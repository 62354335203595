@import "../../mixins/_mixins.scss";

.f6-unbranded {
  & input {
    text-align: left;
    font-size: 15px;
    padding: 10px;
    color: $f6-color-input;
  }

  & .form_main {
    & input[type="checkbox"] {
      height: 20px;
      width: 20px;
      accent-color: $f6-color-accent;
      border-radius: 0;
      flex: none;
      display: inline-block;
    }

    & input[type="button"] {
      font-family: "patua one", sans-serif;
      font-size: 22px;
      line-height: 32px;
      margin-top: 10px;
      background-color: $f6-color-accent;

      &.next_btn {
        width: 100%;
        background-color: $f6-color-accent;
        margin: 0;
      }

      &.prev_btn {
        width: 100%;
        background: transparent;
        color: $f6-color-text;
      }

      &.send_btn {
        margin: 0;
        width: 100%;
      }
    }

    & select {
      padding: 10px;
      font-family: "helvetica", sans-serif;
      font-size: 15px;
      line-height: 22px;
      @include f6Dropdown("./resources/images/icons/icon-dropdown-caret.png");
    }
  }
}
