.lm-branded {
  padding: 0;

  & #lm-home-content-container {
    background-image: url("./resources/images/icons/lm_branded/bg-lm-branded-home.png");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    #lm-img-cnt {
      margin-top: 4rem;
    }
    & #lm-tips-container1 {
      & div {
        display: flex;
        height: auto;
        .text {
          background-image: url("./resources/images/icons/lm_branded/bg-lm-sr1.png");
        }
      }
    }
    & #lm-tips-container2 {
      .text {
        background-image: url("./resources/images/icons/lm_branded/bg-lm-sr2.png");
      }
    }
    & #lm-tips-container3 {
      .text {
        background-image: url("./resources/images/icons/lm_branded/bg-lm-sr3.png");
      }
    }
    & #lm-tips-container4 {
      .text {
        background-image: url("./resources/images/icons/lm_branded/bg-lm-sr4.png");
      }
    }
    & #lm-tips-container5 {
      .text {
        background-image: url("./resources/images/icons/lm_branded/bg-lm-sr5.png");
      }
    }
    & #lm-tips-container1,
    & #lm-tips-container2,
    & #lm-tips-container3,
    & #lm-tips-container4,
    & #lm-tips-container5 {
      .text {
        background-repeat: no-repeat;
        display: flex;
        align-items: center;
        padding-top: 4rem;
        font-size: 19px;
        margin: 1rem;
        color: $lm-color-background;
        background-position: top;
      }
      img {
        width: 200px;
        height: auto;
      }
      div {
        display: flex;
        height: auto;
      }
    }
    & #home-page-stay-tuned {
      background-color: $color-red-shade1;
      margin-left: 1.2rem;
      margin-top: 2rem;
      margin-bottom: 1rem;
      height: auto;
      border-radius: 2rem 0 0 2rem;
      padding: 1rem;
      text-align: center;
      p b {
        color: $lm-color-background;
      }
      & p:nth-child(2) {
        font-family: "helvetica", sans-serif;
        margin: 1rem;
        font-size: 20px;
        line-height: 24px;
        color: $lm-color-text-inverted;
      }
    }
    & #home-page-heading {
      & h1 {
        font-family: "century gothic bold", sans-serif;
        font-size: 32px;
        line-height: 39px;
        text-align: center;
        color: $lm-color-text-inverted;
        padding: 0 50px 20px;
        margin: 0;
      }
    }

    & #home-page-description {
      margin: 2.5rem;
      & p:first-child {
        margin: 0 2rem;
        font-size: 28px;
        line-height: 34px;
        font-family: "helvetica bold", sans-serif;
        margin-bottom: 1rem;
      }
      & p {
        font-family: "helvetica", sans-serif;
        font-size: 19px;
        line-height: 22px;
        text-align: center;
        color: $lm-color-background;
        padding: 0;
        margin: 0;

        & b {
          font-family: "helvetica bold", sans-serif;
          color: $lm-color-background;
        }
      }
    }
    & #home-page-description2 {
      & p {
        font-size: 14px;
        line-height: 20px;
        margin-top: 30px;
        text-align: center;
        font-family: "helvetica", sans-serif;
        color: $color-text;
        & a {
          color: $color-text;
        }
      }
    }
    #lm-home-text-1,
    #lm-home-text-2,
    #lm-home-text-3,
    #lm-home-text-4,
    #lm-home-text-5 {
      p {
        font-family: "helvetica", sans-serif;
        font-size: 18px;
        line-height: 24px;
      }
    }
    #lm-home-text-2,
    #lm-home-text-4 {
      p {
        text-align: right;
        margin-top: 1rem;
      }
    }
    & .button {
      margin-top: 40px;
      background: linear-gradient(
        180deg,
        rgba(236, 19, 94, 1) 0%,
        rgba(178, 0, 42, 1) 100%
      );

      & #quiz-cta {
        background: transparent;
        border: none;

        & span {
          font-family: "century gothic bold", sans-serif;
          font-size: 22px;
          line-height: 34px;
        }
      }
    }
  }
  & #home-quiz-tc-link {
    margin-top: 30px;
    text-align: center;
    & p {
      & a {
        font-family: "helvetica", sans-serif;
        font-size: 24px;
        line-height: 25px;
        color: $lm-color-text-inverted;
        text-decoration: underline;
      }
    }
  }

  & #quizPopup {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(2px);
    z-index: 9;
    & > div {
      width: 80%;
      height: auto;
      text-align: center;
      z-index: 9;
      background-color: #0078e4;
      border: none;
      border-radius: 5px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      padding: 30px 20px;
      & h2 {
        font-family: "century gothic bold", sans-serif;
        font-size: 32px;
        line-height: 39px;
        color: $color-text-inverted;
        margin-top: 0;
      }
      & p {
        font-family: "helvetica", sans-serif;
        font-size: 18px;
        line-height: 22px;
        color: $color-text-inverted;
      }
      & .button {
        width: fit-content;
        margin: 50px auto 0;
        background-color: $color-background;
        padding: 0 20px;
        & #popupButton {
          color: $color-text;
          background-color: transparent;
          margin: 0;
          & span {
            color: $f6-color-accent;
            font-family: "century gothic bold", sans-serif;
            font-size: 22px;
            line-height: 34px;
          }
        }
      }
    }
  }

  & .overlay-container {
    & #home-page-overlay {
      background-color: $lm-color-background;
      border: none;
      border-radius: 5px;

      & #homepage-overlay-heading p {
        font-family: "century gothic bold", sans-serif;
        font-size: 21px;
        line-height: 25px;
        padding-bottom: 40px;
      }

      & #dealercode-and-timestamp p,
      b {
        font-family: "helvetica", sans-serif;
        font-size: 16px;
        line-height: 22px;
      }

      & button {
        background-color: $lm-color-accent;
        width: 90%;

        & span {
          font-family: "century gothic bold", sans-serif;
          font-size: 22px;
          line-height: 34px;
        }
      }
    }
  }
}
@media screen and (min-width: ($m)) {
  #lm-img-cnt {
    margin-left: 15%;
  }
}
@media screen and (min-width: ($lg)) {
  #lm-img-cnt {
    margin-left: 20%;
  }
}
