#main-footer {
  margin-bottom: 84px;

  #ot-sdk-btn {
    color: $color-text;
    border: none;
    background-color: $color-background;
    font-size: 22px;
    line-height: 28px;
    padding: 0;

    &:focus {
      color: $color-red-shade1;
      font-weight: bold;
    }
    &:hover {
      color: $color-red-shade1;
      font-weight: bold;
    }
  }

  & .text {
    margin: 0;
  }

  & .cmp-text {
    & p {
      & a {
        color: $color-text;
        text-decoration: none;
        text-align: center;
        line-height: 15px;
        font-size: 22px;
        font-family: defaultFontLight, sans-serif;

        &:hover {
          border: none;
        }

        &:visited {
          //  color: $color-red-shade1;
        }
      }
    }
  }

  & .separator {
    display: block;
  }
}
