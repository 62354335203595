.textAtLeft {
  .cmp-teaser {
    position: static;
    display: flex;
    margin: 0.75em 0.5em;
  }
  .cmp-teaser__image {
    flex: 3;
    order: 2;
    & img {
      top: 0;
      left: 0;
      display: block;
      width: 100%;
      height: 22em;
      object-fit: cover;
    }
  }
  .cmp-teaser__content {
    position: static;
    top: 0;
    left: 0;
    flex: 2;
    order: 1;
    margin: 0;
    padding: 1rem 0 1rem 0;
    transform: none;
  }
  .cmp-teaser__pretitle {
    color: $color-shade-3;
    font-weight: 300;
    font-size: 0.625em;
    line-height: 1.125rem;
    letter-spacing: 0.025rem;
    text-align: start;
    text-transform: uppercase;
  }
  .cmp-teaser__title {
    margin-top: 0.625rem;
    margin-bottom: 1.1875rem;
    color: $color-text;
    font-size: 1.75em;
    font-family: defaultFont, serif;
    line-height: 2.25rem;
    text-align: start;
    text-decoration: none;
    font-weight: 400;
  }
  .cmp-teaser__title-link {
    color: $color-text;
    font-weight: 400;
    line-height: 2.25rem;
    text-decoration: none;
  }
  .cmp-teaser__description {
    color: $color-text;
    font-weight: 300;
    font-size: 1em;
    line-height: 1.75em;
    text-align: start;
  }
  .cmp-teaser__action-container {
    display: flex;
    justify-content: start;
    margin: 1rem 0;
    padding: 0.125rem;
  }
  .cmp-teaser__action-link {
    margin-right: 0.8em;
    padding: 0.5625em 1em 0.6875em 1em;
    color: $color-text-inverted;
    font-weight: 300;
    text-decoration: none;
    border-radius: 1.56em;
  }

  .cmp-teaser__action-link:first-child {
    background-color: $color-accent;
    border: 0.125em solid $color-accent;

    &:hover {
      background-color: $color-accent-darker;
      border-color: $color-accent-darker;
    }
    &:focus {
      background-color: $color-accent-lighter;
      border-color: $color-background;
      outline: none;
      box-shadow: 0 0 0 0.125em $color-accent-lighter;
    }
    &:disabled {
      background-color: $color-shade-1;
      border-color: $color-shade-1;
    }
  }

  .cmp-teaser__action-link:not(:first-child) {
    color: $color-shade-4;
    background-color: rgba(0, 0, 0, 0);
    border: 0.125em solid $color-shade-4;

    &:hover {
      color: $color-text-inverted;
      background-color: $color-shade-4;
    }
    &:focus {
      color: $color-shade-4;
      background-color: $color-background;
      border: 0.125em solid $color-shade-4;
      outline: none;
      box-shadow: none;
    }
    &:disabled {
      color: $color-shade-1;
      border-color: $color-shade-1;
    }
  }
}
