.form_main {
  flex-wrap: wrap;
  display: flex;
  justify-content: center;
}

p b {
  font-weight: bold;
  color: $color-shade-8;
  font-family: "neuland grotesk condensed bold", sans-serif;
}

.gigya-login-form,
.gigyaraas_screenset {
  text-align: center;
}

.gigya-label-text {
  margin-top: 20px;
}

.form_main input,
.gigya-input-text,
.gigya-input-password {
  font-family: inherit;
  margin: 5px 0;
  font-size: 28px;
  display: flex;
  width: 100%;
  height: 46px;
  background-color: $mlb-color-background;
  border: 1px solid $color-shade-5;
  color: $color-shade-5;
  outline: none;
  border-radius: 10px;
  text-align: center;
  padding: 0;
}

.gigya-input-text,
.gigya-input-password {
  margin: 12px 0;
  border-radius: 10px !important;
  text-align: center !important;
}

.gigya-input-password {
  margin-bottom: 15px;
}

.gigya-screen .gigya-error-msg.gigya-error-msg-active {
  font-size: 15px !important;
  color: $mlb-color-accent !important;
}

.gigya-error-msg {
  color: $mlb-color-accent !important;
}

.gigya-input-text:focus,
.gigya-input-password:focus {
  outline: none;
}

.gigya-label {
  display: none;
}

.gigya-login-form div a:first-child {
  color: $color-shade-8 !important;
  font-size: 22px;
  line-height: 20px;

  &:focus {
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
    font-weight: bold !important;
  }
}

.form_main input[type="button"],
.gigya-button {
  background-color: $mlb-color-accent;
  height: 46px;
  border-radius: 200px;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  vertical-align: middle;
  font-family: "neuland grotesk condensed regular", sans-serif;
  color: $mlb-color-background;
  //text-transform:uppercase;
  margin-top: 50px;
  border: none;
}

.form_main input[type="button"]:focus {
  // font-weight: bold;
  text-decoration: underline;
}

.form_main input[type="button"]:hover {
  // font-weight: bold;
  text-decoration: underline;
}

.form_main .streetName {
  width: 70%;
}

.form_main .streetNumber {
  width: 28%;
  margin-left: 1.5%;
}

.form_main .postalCode {
  width: 28%;
}

.form_main .city {
  width: 70%;
  margin-left: 1.5%;
}

.form_main .telephoneNumberText {
  width: 100%;
  text-align: left;
  padding: 10px;
}

.form_main .telephoneNumberPrefix {
  width: 32%;
  background-color: #fff;
  border: 1px solid #777;
  border-radius: 10px;
  color: #777;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-family: inherit;
  font-size: 28px;
  height: 46px;
  line-height: 34px;
  margin: 5px 0;
  outline: none;
  // padding: 0;
  text-align: center;
  padding: 5px 10px;
  // text-align: center;
  pointer-events: none;
  justify-content: center;
}

.form_main .phone {
  width: 65%;
  margin-left: 1.5%;
}

input[type="button"].prev_btn {
  width: 46px;
  background-image: url(./resources/images/form-back.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: transparent;
  border-radius: 50%;
  border: none;
  height: 46px;
  box-sizing: border-box;
  display: block;
  font-size: 0;
}

input[type="button"].next_btn,
input[type="button"].send_btn {
  width: 77%;
  margin-left: 3%;
}

.form_main input[type="checkbox"],
.gigya-input-checkbox {
  display: inline-block;
  height: 16px;
  width: 16px;
  accent-color: $mlb-color-accent;
  border-radius: 0;
  flex: none;
}

.form_main input[type="checkbox"].invalid {
  outline: 1px solid $mlb-color-accent !important;
  appearance: none !important;
}

.form_main input[type="radio"] {
  height: 16px;
  width: 16px;
  border: 1px solid #d8d8d8;
  appearance: none;
  margin-right: 6px;
}

.form_main .form_radio_group span {
  display: flex;
  align-items: center;
  font-size: 28px;
  line-height: 20px;
}

.form_main .form_radio_group .invalid input[type="radio"] {
  border: 1px solid $mlb-color-accent;
  appearance: none;
}

.form_main .form_radio_group .invalid {
  background-image: none;
  border: none !important;
}

.form_main .form_radio_group .valid {
  background-image: none;
  border: none;
}

.form_main .form_radio_group input[type="radio"]:checked {
  border: none;
  appearance: none;
  background-image: url("./resources/images/radio-checked.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.form_main .form_radio_group {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 16px;
  margin: 10px 5px;
  font: inherit;
}

.form_main .form_radio_group label {
  display: flex;
  justify-content: center;
  align-items: center;
}

.form_main select,
select#gigya-dropdown-birthYear {
  width: 100%;
  height: 46px;
  border-radius: 10px;
  text-align-last: center;
  font-size: 28px;
  line-height: 34px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: transparent;
  background-image: url("./resources/images/dropdown.png");
  background-repeat: no-repeat;
  background-position-x: 95%;
  background-position-y: 50%;
  color: $color-shade-5;
  border: 1px solid $color-shade-5;

  &:focus {
    outline: 1px solid $mlb-color-accent !important;
  }
}

.form_main span .previous_btn {
  width: 46px !important;
  background-image: url(./resources/images/form-back.png) !important;
  background-position: center !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  background-color: transparent !important;
  border-radius: 50% !important;
  border: none !important;
  height: 46px !important;
  box-sizing: border-box !important;
  display: block !important;
  font-size: 0 !important;
}

input[type="date"].valid,
input[type="date"].invalid {
  padding-right: 30px;
}

input[type="date"]:before {
  content: attr(placeholder);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #fff;
  color: rgba(0, 0, 0, 0.65);
  pointer-events: none;
  line-height: 2;
  padding: 0 0.5rem;
  border-radius: inherit;
}

input[type="date"]:focus:before,
input[type="date"]:not([value=""]):before {
  display: none;
}

.form_main input[type="password"]::-ms-reveal {
  display: none;
}
