// .cmp-teaser {
//   position: relative;
// }
// .cmp-teaser__image {
//   & .cmp-image {
//     margin: 0;
//   }
//   & img {
//     display: block;
//     // height: 30em;
//     object-fit: cover;
//   }
// }
// .cmp-teaser__content {
//   position: absolute;
//   top: 50%;
//   left: 50%;
//   transform: translate(-50%, -50%);
// }
// .cmp-teaser__content:only-child {
//   position: relative;
//   top: 0;
//   left: 0;
//   transform: none;
//   .cmp-teaser__pretitle, .cmp-teaser__title, .cmp-teaser__title-link, .cmp-teaser__description  {
//     color: $mlb-color-text;
//   }
// }
// .cmp-teaser__pretitle {
//   color: #ffffff;
//   font-weight: 300;
//   font-size: 0.625em;
//   line-height: 1.125em;
//   letter-spacing: 0.025em;
//   text-align: center;
//   text-transform: uppercase;
// }
// .cmp-teaser__title {
//   margin-top: 0.5625rem;
//   margin-bottom: 1rem;
//   color: #ffffff;
//   font-size: 2.25em;
//   font-family: 'neuland grotesk condensed regular', serif;
//   line-height: 3.0625rem;
//   text-align: center;
//   text-decoration: none;
//   word-break: break-word;
// }
// .cmp-teaser__title-link {
//   color: #ffffff;
//   font-weight: 400;
//   text-decoration: none;
// }
// .cmp-teaser__description {
//   color: #ffffff;
//   font-weight: 300;
//   font-size: 1em;
//   line-height: 1.75em;
//   text-align: center;
// }
// .cmp-teaser__action-container {
//   display: flex;
//   justify-content: center;
//   margin: 1rem 0;
// }
// .cmp-teaser__action-link {
//   margin-right: 0.8em;
//   padding: 0.5625em 1em 0.6875em 1em;
//   color: $mlb-color-text-inverted;
//   font-weight: 300;
//   text-decoration: none;
//   border-radius: 1.56em;
// }

// .cmp-teaser__action-link:first-child {
//   background-color: $color-accent;
//   border: 0.125em solid $color-accent;
// }

// .cmp-teaser__action-link:not(:first-child) {
//   color: $mlb-color-text-inverted;
//   background-color: rgba(0, 0, 0, 0);
//   border: 0.125em solid $mlb-color-background;

//   &:hover {
//     color: $mlb-color-text;
//     background-color: $mlb-color-background;
//   }
//   &:focus {
//     color: $mlb-color-text-inverted;
//     background-color: rgba(0, 0, 0, 0);
//     border: 0.125em solid $mlb-color-background;
//     outline: none;
//     box-shadow: 0 0 0 0.125em $color-accent-lighter;
//   }
//   &:disabled {
//     color: $color-shade-1;
//     border-color: $color-shade-1;
//   }
// }

.cmp-teaser__title {
  font-family: "neuland grotesk condensed bold", sans-serif;
  font-weight: bold;
  font-size: 35px;
  margin: -14px auto 35px;
  text-align: center;
  line-height: 35px;
  word-break: break-word;
  color: $mlb-color-text;
}

.cmp-teaser__description {
  text-align: center;
}

.cmp-teaser__action-link,
.gigya-input-submit {
  background-color: $mlb-color-accent;
  color: $mlb-color-text-inverted;
  height: 46px;
  border: none;
  border-radius: 200px;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  vertical-align: middle;
}

.cmp-teaser__action-link:hover,
.gigya-input-submit:hover {
  color: $mlb-color-text-inverted;
  font-weight: normal;
  text-decoration: underline;
}

.cmp-teaser__action-link:focus,
.gigya-input-submit:focus {
  color: $mlb-color-text-inverted;
  font-weight: normal;
  text-decoration: underline;
}

.cmp-teaser__action-link {
  font-weight: 300;
  text-decoration: none;
  font-size: 28px;
  font-family: "neuland grotesk condensed regular", sans-serif;
  margin: 35px 0;
}

.gigya-input-submit {
  text-decoration: none;
  font-size: 28px;
  line-height: 34px;
  font-family: "neuland grotesk condensed regular", sans-serif;
  margin: 35px 0 0;
  color: $mlb-color-background;
}

#cmp_termsofuse_head {
  & h1 {
    font-size: 30px;
  }
}

// @media screen and (min-width: ($sm + 1)) {

//   .cmp-teaser__action-link,
//   .gigya-input-submit {
//     width: 60%;
//     margin-left: auto;
//     margin-right: auto;
//   }
// }
@media screen and (min-width: ($xxs+1)) {
  .cmp-teaser__title {
    font-size: 44px;
    line-height: 44px;
  }
}

@media screen and (min-width: ($sm + 1)) {
  #cmp_termsofuse_head {
    & h1 {
      font-size: 54px;
    }
  }

  .cmp-teaser__action-link,
  .gigya-input-submit {
    width: 50%;
    margin-left: auto;
    margin-right: auto;
  }
}
