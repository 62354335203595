.lm-unbranded {
  & .gigya-login-form,
  .gigya-profile-form,
  .gigya-resend-verification-code-form {
    & .gigya-input-text,
    .gigya-input-password {
      text-align: left !important;
    }

    & .gigya-composite-control-label {
      font-family: "helvetica", sans-serif;
      font-size: 16px;
      line-height: 32px;
      color: $lm-color-text !important;
      text-align: center;
    }

    & .gigya-composite-control-link {
      font-family: "helvetica", sans-serif;
      font-size: 16px;
      line-height: 32px;
      color: $lm-color-text !important;
      text-align: center;
    }

    & .gigya-input-checkbox {
      accent-color: $lm-color-accent;
    }

    & .gigya-composite-control-submit {
      & .gigya-input-submit {
        background-color: $lm-color-accent;
        color: $lm-color-text-inverted;
        font-family: "century gothic bold", sans-serif;
        font-size: 22px;
        line-height: 0;
        text-align: center;
      }
    }

    & .gigya-error-msg.gigya-error-msg-active {
      font-family: "helvetica", sans-serif;
      font-size: 16px !important;
      line-height: 22px;
    }
  }
}
