@import "../../mixins/_mixins.scss";

.home-page {
  & .profile_section-wrapper {
    margin: 60px 0 0;

    &::before {
      content: "";
      height: 38px;
      width: 7px;
      background-color: $mlb-color-accent;
      position: absolute;
      display: none;
    }

    & .form_heading {
      & h1 {
        font-family: "eurostile lt bold", sans-serif;
        font-size: 32px;
        line-height: 29px;
        text-align: left;
        margin: 10px 0 35px;
        padding: 0;
        position: relative;
        padding-left: 25px;
        color: $color-text;
      }

      & h2 {
        margin: 0;
        color: $color-text;
        &::before {
          content: "";
          display: none;
          background-color: transparent;
        }
      }
    }

    & .profile_section {
      margin: 0 25px;
      font-family: "eurostile lt regular", sans-serif;

      & div {
        & p {
          font-size: 16px;
          line-height: 27px;
          color: $color-text;
        }
      }

      & label {
        display: block;
        margin-top: 30px;
        font-family: "eurostile lt bold", sans-serif;
        font-size: 16px;
        line-height: 20px;
        color: $color-text;
      }

      & select {
        margin-top: 10px;
        padding: 10px;
        font-family: "helvetica", sans-serif;
        font-size: 15px;
        line-height: 22px;
        @include mlbDropdown(
          "./resources/images/icons/icon-dropdown-caret.png"
        );
      }

      & input[type="button"] {
        margin-top: 35px !important;
        background-color: $mlb-color-accent;
        font-family: "neuland grotesk condensed bold", sans-serif;
        font-size: 22px;
        line-height: 34px;
      }
    }
  }

  & .optIn-wrapper {
    background-color: transparent;
    margin-top: 20px;

    &::before {
      content: "";
      height: 24px;
      width: 7px;
      background-color: $mlb-color-accent;
      position: absolute;
      left: 0;
      display: none;
    }

    & .form_heading {
      margin: 0;

      & h1 {
        padding-left: 0;
        font-family: "eurostile lt bold", sans-serif;
        font-size: 18px;
        line-height: 14px;
        text-align: left;
        margin-bottom: 25px;
        color: $color-text;
      }

      & h2 {
        margin: 0;
        color: $color-text;
        font-size: 12px;
        line-height: 14px;
        font-family: "eurostile lt regular", sans-serif;
      }
    }

    & .optIn {
      & .profileOptInDescription {
        margin-bottom: 28px;

        & p,
        b {
          font-family: "eurostile lt regular", sans-serif;
          font-size: 12px;
          line-height: 14px;
          text-align: left;
          color: $color-text;
        }
      }

      & > span {
        padding: 16px 40px 16px 0;
        align-items: center;

        & input[type="checkbox"] {
          background-color: transparent;
        }

        & span p {
          font-family: "eurostile lt regular", sans-serif;
          font-size: 12px;
          line-height: 14px;
          color: $color-text;
        }
      }

      & .disclaimer {
        padding: 0;
        margin-top: 28px;

        & p {
          font-family: "eurostile lt regular", sans-serif;
          font-size: 12px;
          line-height: 14px;
          text-align: left;
          border: none;
          color: $color-text;
        }
      }
    }
  }

  & #cmp-contact-info {
    background-color: transparent;
    margin: 0;
    padding: 0 25px 25px;
    &::before {
      content: "";
      height: 24px;
      width: 7px;
      background-color: $mlb-color-accent;
      position: absolute;
      top: 30px;
      left: 0;
      display: none;
    }

    & h3 {
      font-family: "eurostile lt bold", sans-serif;
      font-size: 18px;
      line-height: 20px;
      text-align: left;
      margin-top: 0;
      color: $color-text;
    }

    & p {
      font-family: "eurostile lt regular", sans-serif;
      font-size: 16px;
      // line-height: 22px;
      text-align: left;
      padding: 0;
      background-repeat: no-repeat;
      background-position: left center;
      padding-left: 45px;
      vertical-align: middle;
      line-height: normal;
      background-size: 20px 20px;
      color: $color-text;

      &:first-of-type {
        background-image: url("./resources/images/icons/mlb_branded/icon-phone-mlb-red.png");

        & a {
          color: $color-text;
          font-family: "eurostile lt regular", sans-serif;
          font-size: 12px;
          line-height: 14px;
          &::before {
            content: "";
            background: transparent;
            display: none;
          }
        }
      }

      &:last-of-type {
        background-image: url("./resources/images/icons/mlb_branded/icon-email-mlb-red.png");

        & a {
          color: $color-text;
          font-family: "eurostile lt regular", sans-serif;
          font-size: 12px;
          line-height: 14px;
          &::before {
            content: "";
            background: transparent;
            display: none;
            color: $color-text;
          }
        }
      }
    }
  }
}
