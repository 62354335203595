.popup-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(2px);
  z-index: 9;
}

.popup {
  width: 90%;
  height: auto;
  border: 1px solid #c9102f;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  z-index: 9;
  padding-bottom: 27px;
}

.popup .popup-contents {
  max-height: 60vh;
  overflow-y: auto;
}

.popup p {
  font-size: 28px;
  line-height: 32px;
  padding: 0 17px;
  margin: 0;
}

.popup a {
  text-decoration: none;
  // text-decoration-thickness: 1px;
  color: $color-shade-8;
  font-family: "neuland grotesk condensed regular", sans-serif;
  font-size: 22px;
}

.popup-btn {
  width: 90%;
  border: none;
  outline: none;
  background-color: $mlb-color-accent;
  height: 46px;
  border-radius: 200px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  vertical-align: middle;
  font-family: "neuland grotesk condensed regular", sans-serif;
  font-size: 28px;
  line-height: 32px;
  margin: 36px auto 0 !important;
  color: $mlb-color-background;

  //  text-transform:uppercase;
  &:focus {
    text-decoration: underline;
  }
}

.close-btn {
  position: relative;
  top: -10px;
  left: 90%;
  width: 20px;
  height: 20px;
  background-image: url("./resources/images/mlb-close-popup.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.display-block {
  display: block;
}

.display-none {
  display: none;
}

.action_unsubscribe .success-icon {
  background-image: url("./resources/images/popupSuccess.png");
  width: 75px;
  height: 75px;
  background-repeat: no-repeat;
  background-position: center;
  margin: 0 auto;
}

.action_unsubscribe .error-icon {
  background-image: url("./resources/images/popupError.png");
  width: 75px;
  height: 75px;
  background-repeat: no-repeat;
  background-position: center;
  margin: 0 auto;
}

.overlay-container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(2px);
  z-index: 9;
  display: none;
}

#home-page-overlay {
  // position: fixed;
  top: 20%;
  left: 5%;
  right: 5%;
  border: 1px solid $mlb-color-accent;
  width: 90%;
  height: auto;
  text-align: center;
  position: absolute;
  background-color: $mlb-color-background;
  z-index: 9;
  padding-top: 70px;

  & div.button {
    background-color: $mlb-color-background;
    padding-bottom: 66px;
    padding-top: 50px;
  }

  & div button {
    background-color: $color-shade-green;
    border: none;
    border-radius: 24px;
    height: 48px;
    width: 70%;

    & span.cmp-button__text {
      font-size: 28px;
      line-height: 24px;
    }
  }
}

#dealar-code-error-msg {
  display: none;
}
