.lm {
  & #main-footer {
    margin-top: auto;

    & #footer-copyright {
      margin: 10px auto 30px;

      & p {
        font-family: "century gothic", sans-serif;
        font-size: 16px;
        line-height: 22px;
        color: $color-shade-5;
      }
    }

    & .cmp-text {
      & p {
        margin: 5px 0;

        & a {
          font-family: "century gothic", sans-serif;
          font-size: 16px;
          line-height: 22px;
          color: $color-shade-8;
        }
      }
    }

    & #ot-sdk-btn {
      margin: 5px 0;
      font-family: "century gothic", sans-serif;
      font-size: 16px;
      line-height: 22px;
      color: $color-shade-8;
    }

    & #cmp-stickyFooter {
      margin-top: 2px solid $color-shade-5;

      & p {
        font-family: "helvetica", sans-serif;
        color: $lm-color-text;
      }
    }
  }

  // sticky footer top bar
  & #cmp-stickyFooter {
    &::before {
      content: "";
      width: 100%;
      height: 1.5px;
      background-image: none;
      background-color: rgba($color: $lm-color-input, $alpha: 0.6);
      position: absolute;
      top: 0;
      left: 0;
    }
  }
}
