.cmp-contentfragment__element--justscanbaseurl,
.cmp-contentfragment__element--justscanlanguage,
.cmp-contentfragment__element--justscanuserid,
.cmp-contentfragment__element--justscancampaigncode,
[class*='cmp-contentfragment--justscan'] .cmp-contentfragment__title,
[class*='cmp-contentfragment--justscan'] .cmp-contentfragment__description {
    // display: none;
    height: 0 !important;
    width: 0 !important;
    opacity: 0 !important;
}
.contentfragment{
    display: none;
}
.close-justscan {
    position: absolute;
    top: 10px;
    right: 5%;
    background-color: transparent;
    color: $color-text-inverted;
    border: none;
    outline: none;
}
.scanpack-scenario {
    overflow: hidden;
    // width: 100%;
    height: calc(100vh - 83px);
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: #000;
    // border-radius: 20px;
    box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.4);
    z-index: 100;
}

.scanpack-scenario:after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: black;
    z-index: 10;
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.5s ease-in-out;
}

.scanpack-scenario.loading:after {
    opacity: 1;
}

iframe {
    width: 100%;
    height: 100%;
    border: none;
}
