//== Font loading

@mixin fontface($name, $file, $weight: normal, $style: normal) {
  @font-face {
    font-weight: $weight;
    font-family: "#{$name}";
    font-style: $style;
    src: url("resources/fonts/#{$file}.ttf") format("truetype");
  }
}

@mixin fontfaceotf($name, $file, $weight: normal, $style: normal) {
  @font-face {
    font-weight: $weight;
    font-family: "#{$name}";
    font-style: $style;
    src: url("resources/fonts/#{$file}.otf") format("opentype");
  }
}

// marlboro
@include fontface(
  "neuland grotesk condensed regular",
  "NeulandGrotesk/NeulandGrotesk-CondensedRegular"
);
@include fontface(
  "neuland grotesk condensed bold",
  "NeulandGrotesk/NeulandGrotesk-CondensedBold"
);
@include fontface(
  "neuland grotesk condensed light",
  "NeulandGrotesk/NeulandGrotesk-CondensedLight"
);
@include fontface(
  "neuland grotesk condensed regular",
  "NeulandGrotesk/NeulandGrotesk-CondensedRegular"
);
@include fontface(
  "neuland grotesk regular",
  "NeulandGrotesk/NeulandGrotesk-Regular"
);
@include fontface(
  "neuland grotesk light",
  "NeulandGrotesk/NeulandGrotesk-Light"
);
@include fontfaceotf("eurostile lt regular", "Eurostile/Eurostile-LT-Std");
@include fontfaceotf("eurostile lt bold", "Eurostile/Eurostile-LT-Std-Bold");
@include fontface('hackney','Hackney/Hackney-Vector');

// L&M
@include fontface("century gothic bold", "CenturyGothic/Century-Gothic-bold");
@include fontface("century gothic", "CenturyGothic/Century-Gothic-regular");
@include fontface("helvetica", "Helvetica/Helvetica-regular");
@include fontface("helvetica bold", "Helvetica/Helvetica-bold");
// F6
@include fontface("patua one", "PatuaOne/PatuaOne-regular");
@include fontfaceotf(
  "helvetica neue condensed bold",
  "Helvetica/Helvetica-neue-ltstd-bold-condensed"
);
@include fontfaceotf(
  "helvetica neue condensed",
  "Helvetica/Helvetica-neue-ltstd-regular-condensed"
);
@include fontfaceotf(
  "helvetica neue condensed light",
  "Helvetica/Helvetica-neue-ltstd-light-condensed"
);
@include fontfaceotf("helvetica neue light", "Helvetica/HelveticaNeueLight");
@include fontfaceotf("helvetica neue medium", "Helvetica/HelveticaNeueMedium");
// @include fontface("Neuland Grotesk Condensed", 'NeulandGrotesk/NeulandGrotesk-Bold', bold);
// @include fontface("Neuland Grotesk Condensed", 'NeulandGrotesk/NeulandGrotesk-CondensedBold', bold);
// @include fontface("Neuland Grotesk Condensed", 'NeulandGrotesk/NeulandGrotesk-CondensedLight', light);
// @include fontface("Neuland Grotesk Condensed", 'NeulandGrotesk/NeulandGrotesk-CondensedRegular');
// @include fontface("Neuland Grotesk Condensed", 'NeulandGrotesk/NeulandGrotesk-Light', light);
// @include fontface("Neuland Grotesk Condensed", 'NeulandGrotesk/NeulandGrotesk-Regular');

// @include fontface(defaultFont, 'SourceSansPro/SourceSansPro-Black', bolder);
// @include fontface(defaultFont, 'SourceSansPro/SourceSansPro-BlackItalic', bolder, italic);
// @include fontface(defaultFont, 'SourceSansPro/SourceSansPro-Bold', bolder);
// @include fontface(defaultFont, 'SourceSansPro/SourceSansPro-BoldItalic', bolder, italic);
// @include fontface(defaultFont, 'SourceSansPro/SourceSansPro-ExtraLight', lighter);
// @include fontface(defaultFont, 'SourceSansPro/SourceSansPro-ExtraLightItalic', lighter, italic);
// @include fontface(defaultFont, 'SourceSansPro/SourceSansPro-Italic', normal, italic);
// @include fontface(defaultFont, 'SourceSansPro/SourceSansPro-Light', lighter);
// @include fontface(defaultFont, 'SourceSansPro/SourceSansPro-LightItalic', lighter, italic);
// @include fontface(defaultFont, 'SourceSansPro/SourceSansPro-Regular', normal);
// @include fontface(defaultFont, 'SourceSansPro/SourceSansPro-SemiBold', bold);
// @include fontface(defaultFont, 'SourceSansPro/SourceSansPro-SemiBoldItalic', bold, italic);
// @include fontface(titleFont, 'SourceSerifPro/SourceSerifPro-Black', bolder);
// @include fontface(titleFont, 'SourceSerifPro/SourceSerifPro-BlackItalic', bolder, italic);
// @include fontface(titleFont, 'SourceSerifPro/SourceSerifPro-Bold', bolder);
// @include fontface(titleFont, 'SourceSerifPro/SourceSerifPro-BoldItalic', bolder, italic);
// @include fontface(titleFont, 'SourceSerifPro/SourceSerifPro-ExtraLight', lighter);
// @include fontface(titleFont, 'SourceSerifPro/SourceSerifPro-ExtraLightItalic', lighter, italic);
// @include fontface(titleFont, 'SourceSerifPro/SourceSerifPro-Italic', normal, italic);
// @include fontface(titleFont, 'SourceSerifPro/SourceSerifPro-Light', lighter);
// @include fontface(titleFont, 'SourceSerifPro/SourceSerifPro-LightItalic', lighter, italic);
// @include fontface(titleFont, 'SourceSerifPro/SourceSerifPro-Regular', normal);
// @include fontface(titleFont, 'SourceSerifPro/SourceSerifPro-SemiBold', bold);
// @include fontface(titleFont, 'SourceSerifPro/SourceSerifPro-SemiBoldItalic', bold, italic);
