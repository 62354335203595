.loader-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 9;
}

.loader {
    font-size: 12px;
    margin: 0 auto;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 1em;
    height: 1em;
    border-radius: 50%;
    text-indent: -9999em;
    -webkit-animation: mlbLoader 1.1s infinite ease;
    animation: mlbLoader 1.1s infinite ease;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
}

@-webkit-keyframes mlbLoader {

    0%,
    100% {
        box-shadow: 0em -2.6em 0em 0em #c9102f, 1.8em -1.8em 0 0em rgba(201, 16, 47, 0.2), 2.5em 0em 0 0em rgba(201, 16, 47, 0.2), 1.75em 1.75em 0 0em rgba(201, 16, 47, 0.2), 0em 2.5em 0 0em rgba(201, 16, 47, 0.2), -1.8em 1.8em 0 0em rgba(201, 16, 47, 0.2), -2.6em 0em 0 0em rgba(201, 16, 47, 0.5), -1.8em -1.8em 0 0em rgba(201, 16, 47, 0.7);
    }

    12.5% {
        box-shadow: 0em -2.6em 0em 0em rgba(201, 16, 47, 0.7), 1.8em -1.8em 0 0em #c9102f, 2.5em 0em 0 0em rgba(201, 16, 47, 0.2), 1.75em 1.75em 0 0em rgba(201, 16, 47, 0.2), 0em 2.5em 0 0em rgba(201, 16, 47, 0.2), -1.8em 1.8em 0 0em rgba(201, 16, 47, 0.2), -2.6em 0em 0 0em rgba(201, 16, 47, 0.2), -1.8em -1.8em 0 0em rgba(201, 16, 47, 0.5);
    }

    25% {
        box-shadow: 0em -2.6em 0em 0em rgba(201, 16, 47, 0.5), 1.8em -1.8em 0 0em rgba(201, 16, 47, 0.7), 2.5em 0em 0 0em #c9102f, 1.75em 1.75em 0 0em rgba(201, 16, 47, 0.2), 0em 2.5em 0 0em rgba(201, 16, 47, 0.2), -1.8em 1.8em 0 0em rgba(201, 16, 47, 0.2), -2.6em 0em 0 0em rgba(201, 16, 47, 0.2), -1.8em -1.8em 0 0em rgba(201, 16, 47, 0.2);
    }

    37.5% {
        box-shadow: 0em -2.6em 0em 0em rgba(201, 16, 47, 0.2), 1.8em -1.8em 0 0em rgba(201, 16, 47, 0.5), 2.5em 0em 0 0em rgba(201, 16, 47, 0.7), 1.75em 1.75em 0 0em #c9102f, 0em 2.5em 0 0em rgba(201, 16, 47, 0.2), -1.8em 1.8em 0 0em rgba(201, 16, 47, 0.2), -2.6em 0em 0 0em rgba(201, 16, 47, 0.2), -1.8em -1.8em 0 0em rgba(201, 16, 47, 0.2);
    }

    50% {
        box-shadow: 0em -2.6em 0em 0em rgba(201, 16, 47, 0.2), 1.8em -1.8em 0 0em rgba(201, 16, 47, 0.2), 2.5em 0em 0 0em rgba(201, 16, 47, 0.5), 1.75em 1.75em 0 0em rgba(201, 16, 47, 0.7), 0em 2.5em 0 0em #c9102f, -1.8em 1.8em 0 0em rgba(201, 16, 47, 0.2), -2.6em 0em 0 0em rgba(201, 16, 47, 0.2), -1.8em -1.8em 0 0em rgba(201, 16, 47, 0.2);
    }

    62.5% {
        box-shadow: 0em -2.6em 0em 0em rgba(201, 16, 47, 0.2), 1.8em -1.8em 0 0em rgba(201, 16, 47, 0.2), 2.5em 0em 0 0em rgba(201, 16, 47, 0.2), 1.75em 1.75em 0 0em rgba(201, 16, 47, 0.5), 0em 2.5em 0 0em rgba(201, 16, 47, 0.7), -1.8em 1.8em 0 0em #c9102f, -2.6em 0em 0 0em rgba(201, 16, 47, 0.2), -1.8em -1.8em 0 0em rgba(201, 16, 47, 0.2);
    }

    75% {
        box-shadow: 0em -2.6em 0em 0em rgba(201, 16, 47, 0.2), 1.8em -1.8em 0 0em rgba(201, 16, 47, 0.2), 2.5em 0em 0 0em rgba(201, 16, 47, 0.2), 1.75em 1.75em 0 0em rgba(201, 16, 47, 0.2), 0em 2.5em 0 0em rgba(201, 16, 47, 0.5), -1.8em 1.8em 0 0em rgba(201, 16, 47, 0.7), -2.6em 0em 0 0em #c9102f, -1.8em -1.8em 0 0em rgba(201, 16, 47, 0.2);
    }

    87.5% {
        box-shadow: 0em -2.6em 0em 0em rgba(201, 16, 47, 0.2), 1.8em -1.8em 0 0em rgba(201, 16, 47, 0.2), 2.5em 0em 0 0em rgba(201, 16, 47, 0.2), 1.75em 1.75em 0 0em rgba(201, 16, 47, 0.2), 0em 2.5em 0 0em rgba(201, 16, 47, 0.2), -1.8em 1.8em 0 0em rgba(201, 16, 47, 0.5), -2.6em 0em 0 0em rgba(201, 16, 47, 0.7), -1.8em -1.8em 0 0em #c9102f;
    }
}

@keyframes mlbLoader {

    0%,
    100% {
        box-shadow: 0em -2.6em 0em 0em #c9102f, 1.8em -1.8em 0 0em rgba(201, 16, 47, 0.2), 2.5em 0em 0 0em rgba(201, 16, 47, 0.2), 1.75em 1.75em 0 0em rgba(201, 16, 47, 0.2), 0em 2.5em 0 0em rgba(201, 16, 47, 0.2), -1.8em 1.8em 0 0em rgba(201, 16, 47, 0.2), -2.6em 0em 0 0em rgba(201, 16, 47, 0.5), -1.8em -1.8em 0 0em rgba(201, 16, 47, 0.7);
    }

    12.5% {
        box-shadow: 0em -2.6em 0em 0em rgba(201, 16, 47, 0.7), 1.8em -1.8em 0 0em #c9102f, 2.5em 0em 0 0em rgba(201, 16, 47, 0.2), 1.75em 1.75em 0 0em rgba(201, 16, 47, 0.2), 0em 2.5em 0 0em rgba(201, 16, 47, 0.2), -1.8em 1.8em 0 0em rgba(201, 16, 47, 0.2), -2.6em 0em 0 0em rgba(201, 16, 47, 0.2), -1.8em -1.8em 0 0em rgba(201, 16, 47, 0.5);
    }

    25% {
        box-shadow: 0em -2.6em 0em 0em rgba(201, 16, 47, 0.5), 1.8em -1.8em 0 0em rgba(201, 16, 47, 0.7), 2.5em 0em 0 0em #c9102f, 1.75em 1.75em 0 0em rgba(201, 16, 47, 0.2), 0em 2.5em 0 0em rgba(201, 16, 47, 0.2), -1.8em 1.8em 0 0em rgba(201, 16, 47, 0.2), -2.6em 0em 0 0em rgba(201, 16, 47, 0.2), -1.8em -1.8em 0 0em rgba(201, 16, 47, 0.2);
    }

    37.5% {
        box-shadow: 0em -2.6em 0em 0em rgba(201, 16, 47, 0.2), 1.8em -1.8em 0 0em rgba(201, 16, 47, 0.5), 2.5em 0em 0 0em rgba(201, 16, 47, 0.7), 1.75em 1.75em 0 0em #c9102f, 0em 2.5em 0 0em rgba(201, 16, 47, 0.2), -1.8em 1.8em 0 0em rgba(201, 16, 47, 0.2), -2.6em 0em 0 0em rgba(201, 16, 47, 0.2), -1.8em -1.8em 0 0em rgba(201, 16, 47, 0.2);
    }

    50% {
        box-shadow: 0em -2.6em 0em 0em rgba(201, 16, 47, 0.2), 1.8em -1.8em 0 0em rgba(201, 16, 47, 0.2), 2.5em 0em 0 0em rgba(201, 16, 47, 0.5), 1.75em 1.75em 0 0em rgba(201, 16, 47, 0.7), 0em 2.5em 0 0em #c9102f, -1.8em 1.8em 0 0em rgba(201, 16, 47, 0.2), -2.6em 0em 0 0em rgba(201, 16, 47, 0.2), -1.8em -1.8em 0 0em rgba(201, 16, 47, 0.2);
    }

    62.5% {
        box-shadow: 0em -2.6em 0em 0em rgba(201, 16, 47, 0.2), 1.8em -1.8em 0 0em rgba(201, 16, 47, 0.2), 2.5em 0em 0 0em rgba(201, 16, 47, 0.2), 1.75em 1.75em 0 0em rgba(201, 16, 47, 0.5), 0em 2.5em 0 0em rgba(201, 16, 47, 0.7), -1.8em 1.8em 0 0em #c9102f, -2.6em 0em 0 0em rgba(201, 16, 47, 0.2), -1.8em -1.8em 0 0em rgba(201, 16, 47, 0.2);
    }

    75% {
        box-shadow: 0em -2.6em 0em 0em rgba(201, 16, 47, 0.2), 1.8em -1.8em 0 0em rgba(201, 16, 47, 0.2), 2.5em 0em 0 0em rgba(201, 16, 47, 0.2), 1.75em 1.75em 0 0em rgba(201, 16, 47, 0.2), 0em 2.5em 0 0em rgba(201, 16, 47, 0.5), -1.8em 1.8em 0 0em rgba(201, 16, 47, 0.7), -2.6em 0em 0 0em #c9102f, -1.8em -1.8em 0 0em rgba(201, 16, 47, 0.2);
    }

    87.5% {
        box-shadow: 0em -2.6em 0em 0em rgba(201, 16, 47, 0.2), 1.8em -1.8em 0 0em rgba(201, 16, 47, 0.2), 2.5em 0em 0 0em rgba(201, 16, 47, 0.2), 1.75em 1.75em 0 0em rgba(201, 16, 47, 0.2), 0em 2.5em 0 0em rgba(201, 16, 47, 0.2), -1.8em 1.8em 0 0em rgba(201, 16, 47, 0.5), -2.6em 0em 0 0em rgba(201, 16, 47, 0.7), -1.8em -1.8em 0 0em #c9102f;
    }
}