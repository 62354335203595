// this dropdown is not used currently.
.f6-unbranded {
  & .dropdown-wrapper {
    border: 1px solid #777777;
    border-radius: 10px;
    width: 100%;
    height: auto;
    text-align: center;
    box-sizing: border-box;
    /* overflow: hidden; */
    cursor: pointer;
    font-family: "helvetica", sans-serif;
    font-size: 15px;
    line-height: 22px;
  }

  & .dropdown-head,
  .dropdown-body {
    width: 100%;
    height: auto;
    text-align: center;
  }

  & .dropdown-head {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    position: relative;
    height: 46px;
  }

  & .dropdown-title {
    width: 100%;
    text-align: left;
  }

  /* .dropdown-title p{
        text-align: center;
        margin: 0 auto;
        
    } */
  & .dropdown-img {
    position: absolute;
    top: 50%;
    right: 5%;
  }

  & .dropdown-option {
    width: 100%;
    margin: 15px auto;
  }

  & .selected-icon {
    background-image: url("./resources/images/radio-checked.png");
    background-repeat: no-repeat;
    background-position: 20% 50%;
    color: $f6-color-accent;
    font-weight: bold;
  }

  & .invalid {
    border: 1px solid #c9102f;
  }
}
