.call-to-action {
  .cmp-button {
    background-color: $color-accent;
    border-color: $color-accent;
    &:hover {
      background-color: $color-accent-darker;
      border-color: $color-accent-darker;
    }
    &:focus {
      background-color: $color-accent-lighter;
      border-color: $color-background;
      outline: none;
      box-shadow: none;
    }
    &:disabled {
      background-color: $color-shade-1;
      border-color: $color-shade-1;
      & .cmp-button__text {
        color: $color-shade-2;
      }
    }
  }

  .cmp-button__text {
    color: $color-text-inverted;
  }

  // .cmp-button__icon {
  // }
}
