.teaser.dark {
  // .cmp-teaser {
  // }
  // .cmp-teaser__image {
  // }
  .cmp-teaser__content {
    padding: 1.5rem;
    background-color: $color-shade-3;
  }
  .cmp-teaser__pretitle {
    color: $color-text-inverted;
  }
  .cmp-teaser__title {
    color: $color-text-inverted;
  }
  .cmp-teaser__title-link {
    color: $color-text-inverted;
  }
  .cmp-teaser__description {
    color: $color-text-inverted;
  }
  // .cmp-teaser__action-container {
  // }
  .cmp-teaser__action-link {
    color: $color-text-inverted;
  }

  .cmp-teaser__action-link:first-child {
    background-color: $color-accent;
    border-color: $color-accent;
    border-style: solid;
    border-width: 0.125em;

    &:hover {
      background-color: $color-accent-darker;
      border-color: $color-accent-darker;
    }
    &:focus {
      background-color: $color-accent-lighter;
      border-color: $color-background;
      outline: none;
      box-shadow: 0 0 0 0.125em $color-accent-lighter;
    }
    &:disabled {
      background-color: $color-shade-1;
      border-color: $color-shade-1;
    }
  }
  .cmp-teaser__action-link:not(:first-child) {
    color: $color-text-inverted;
    background-color: rgba(0, 0, 0, 0);
    border-color: $color-background;
    border-style: solid;
    border-width: 0.125em;

    &:hover {
      color: $color-text;
      background-color: $color-background;
    }
    &:focus {
      background-color: rgba(0, 0, 0, 0);
      border-color: $color-background;
      border-style: solid;
      border-width: 0.125em;
      outline: none;
      box-shadow: 0 0 0 0.125em $color-accent-lighter;
    }
    &:disabled {
      color: $color-shade-3;
      border-color: $color-shade-3;
    }
  }
}
