#taf-top-copy,
#taf-top-copy-desktop {
  & h1 {
    font-size: 54px;
    line-height: 52px;
    text-align: left;
    font-family: "neuland grotesk condensed bold", sans-serif;
    color: $mlb-color-text;
    margin-bottom: 0;
    padding: 29px 10% 18px 21px;
  }

  & p {
    font-size: 28px;
    line-height: 32px;
    text-align: left;
    font-family: "neuland grotesk condensed light", sans-serif;
    color: $color-shade-8;
    padding: 0 13px 0 21px;
  }
}

#taf-top-copy-desktop {
  display: none;
}

#taf-bottom-copy {
  & h1 {
    font-size: 54px;
    line-height: 52px;
    text-align: left;
    font-family: "neuland grotesk condensed bold", sans-serif;
    color: $mlb-color-text;
    margin-bottom: 0;
    padding: 29px 10% 18px 21px;
  }

  & p {
    font-size: 28px;
    line-height: 32px;
    text-align: left;
    font-family: "neuland grotesk condensed light", sans-serif;
    color: $color-shade-8;
    padding: 0 13px 0 21px;
  }
}

#taf-links-container {
  background-color: #f5f5f5;
  padding: 19px 0;
  margin: 36px 0 21px;

  & > div {
    display: flex;
    justify-content: space-between;
    align-items: center;

    & > div:last-child {
      display: none;
    }
  }

  #taf-email-link,
  #taf-whatsapp-link,
  #copy-to-clipboard-desktop {
    & img {
      width: 116px;
      height: 116px;
      filter: drop-shadow(0px 6px 8px rgba(0, 0, 0, 0.38));

      &:focus {
        border: 2px solid $color-foreground;
        border-radius: 50%;
      }

      &:hover {
        border: 2px solid $color-foreground;
        border-radius: 50%;
      }
    }
  }
}

#taf-copy-link {
  padding-left: 16px;

  & div {
    display: flex;
    align-items: center;
  }

  & img {
    width: 58px;
    height: 58px;
    filter: drop-shadow(0px 6px 8px rgba(0, 0, 0, 0.38));

    &:focus {
      border: 2px solid $color-foreground;
      border-radius: 50%;
    }

    &:hover {
      border: 2px solid $color-foreground;
      border-radius: 50%;
    }
  }

  & p {
    padding: 0 27px;
  }

  & #copy-link {
    display: none;
  }
}

#copy-toast {
  visibility: hidden;
  width: fit-content;
  padding: 10px 20px;
  background-color: #333;
  color: #fff;
  text-align: center;
  border-radius: 8px;
  position: fixed;
  z-index: 10;
  left: 50%;
  transform: translateX(-50%);
  bottom: 70px;
  font-size: 8px;
}

#copy-toast.show {
  visibility: visible;
  -webkit-animation: fadein 0.5s, fadeout 0.5s 2.5s;
  animation: fadein 0.5s, fadeout 0.5s 2.5s;
}

@-webkit-keyframes fadein {
  from {
    bottom: 0;
    opacity: 0;
  }

  to {
    bottom: 70px;
    opacity: 1;
  }
}

@keyframes fadein {
  from {
    bottom: 0;
    opacity: 0;
  }

  to {
    bottom: 70px;
    opacity: 1;
  }
}

@-webkit-keyframes fadeout {
  from {
    bottom: 70px;
    opacity: 1;
  }

  to {
    bottom: 0;
    opacity: 0;
  }
}

@keyframes fadeout {
  from {
    bottom: 70px;
    opacity: 1;
  }

  to {
    bottom: 0;
    opacity: 0;
  }
}

@media screen and (min-width: ($lg)) {
  #taf-top-copy {
    display: none;
  }

  #taf-top-copy-desktop {
    display: block;
  }

  #taf-links-container {
    & > div {
      & > div:last-child {
        display: block !important;
      }
    }
  }

  #taf-whatsapp-link {
    display: none !important;
  }

  #taf-copy-link {
    display: none;
  }
}
