// .cmp-text {
//   margin: 0.75rem 0 0 0;
//   color: $color-shade-6;
//   font-weight: normal;
//   font-size: 1em;
//   text-align: center;
//   & h1{
//     font-family: 'neuland grotesk condensed regular';
//     color: $color-foreground;
//     text-align: center;
//     font-weight: bold;
//     opacity: 100%;
//   }
//   & b {
//     font-weight: bold;
//   }
//   & p a {
//     color: $mlb-color-accent;
//     // text-decoration: none;
//     /* border: 0.125em solid $color-transparent;
//     border-radius: 0.25em; */
//     text-decoration-thickness: 1px;
//     &:hover {
//       color: $mlb-color-accent;
//       text-decoration: underline;
//       text-decoration-thickness: 1px;
//       text-decoration-color: $mlb-color-accent;
//       text-decoration-style: solid;
//     }
//     &:focus {
//       text-decoration: underline;
//       text-decoration-thickness: 1px;
//       /* border-color: $color-accent; */
//       outline: none;
//       /*  text-decoration-thickness: 0.125em; */
//       text-decoration-color: $mlb-color-accent;
//       text-decoration-style: solid;
//     }
//   }
//   & blockquote {
//     margin: 0.75rem 0;
//     font-size: 1.75em;
//     font-family: 'neuland grotesk condensed regular', serif;
//     font-style: italic;
//     line-height: 2.375rem;
//     margin-inline: 0;
//   }
//   & ul li::marker {
//     content: '-  ';
//   }
// }
// .cmp-text__paragraph {
// }

#cmp-Link {
  & a {
    color: #777777;
  }
}

.cmp-text {
  & h2 {
    margin-top: 43px;
    font-stretch: condensed;
    //padding-top: 20px;
  }

  & h3 {
    color: $color-shade-6;
  }
}