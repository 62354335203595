.f6 {
  & .popup-wrapper {
    & .popup {
      width: 90%;
      height: auto;
      text-align: center;
      z-index: 9;
      background-color: $f6-color-background;
      border: none;
      border-radius: 5px;
      padding-bottom: 27px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      & .close-btn {
        position: relative;
        top: 10px;
        left: 90%;
        width: 22px;
        height: 22px;
        background-image: url("./resources/images/icons/icon-close-popup.png");
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        cursor: pointer;
      }

      & .popup-contents {
        width: 90%;
        margin: 30px auto 0;

        & .success-icon {
          background: none;
          width: 0;
          height: 0;
          display: none;
        }

        & .error-icon {
          background: none;
          width: 0;
          height: 0;
          display: none;
        }

        & h2 {
          font-family: "patua one", sans-serif;
          font-size: 21px;
          line-height: 25px;
          margin-bottom: 34px;
        }

        & p {
          font-family: "helvetica", sans-serif;
          font-size: 16px;
          line-height: 22px;
          text-align: center;
          margin-bottom: 34px;
        }

        & a {
          font-size: 16px;
          line-height: 22px;
          text-align: center;
          font-family: "helvetica", sans-serif;
        }

        & .popup-btn {
          width: 100%;
          height: 46px;
          box-sizing: border-box;
          text-align: center;
          color: $f6-color-text-inverted;
          background-color: $f6-color-accent;
          border: none;
          border-radius: 200px;
          outline: none;
          display: flex;
          justify-content: center;
          align-items: center;
          font-family: "patua one", sans-serif;
          font-size: 22px;
          line-height: 34px;
          margin: 0 auto !important;
        }
      }
    }
  }

  &.f6-quiz {
    & .popup-wrapper {
      & .popup {
        background-color: $f6-color-accent;
        color: $color-text-inverted;
        width: 75%;
        border-radius: 0;

        & .popup-contents {
          & h1 {
            font-family: "patua one", sans-serif;
            font-size: 42px;
            line-height: 48px;
            margin: 0 auto 26px;
            color: $color-text-inverted;
          }

          & .popup-btn {
            background-color: $color-background;
            color: $f6-color-accent;
            width: fit-content;
            padding: 0 24px !important;
            font-size: 22px;
            font-family: "patua one", sans-serif;
          }

          & p {
            font-size: 18px;
            line-height: 22px;
            font-family: "helvetica neue condensed", sans-serif;
            color: $f6-color-text-inverted;
            text-align: center;
          }
        }
      }
    }
  }
}
