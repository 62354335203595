.form_heading:first-child {
  text-align: center;
  font-size: 54px;
  font-family: "neuland grotesk condensed regular", sans-serif;
  line-height: 54px;
  font-weight: bold;
  word-break: break-word;
}

.form_heading:nth-child(2) {
  font-size: 28px;
  font-weight: lighter;
  color: #4a4a4a;
  line-height: 28px;
  margin: 0 0 29px;
  text-align: center;
}

.cmp_reg_form2-wrapper,
.cmp_reg_form4-wrapper {
  & .form_heading h1 {
    padding-top: 15px;
  }
}

.form_main .password-policy {
  margin-top: 40px;
  width: 100%;
}

.form_main .password-policy p {
  font-size: 22px;
  line-height: 27px;
  font-family: "neuland grotesk condensed light", sans-serif;
  text-align: left;
  margin: 0;
}

.form_main .password-policy ul {
  display: flex;
  flex-wrap: wrap;
  padding-left: 10px;
  margin-bottom: 0;
}

.form_main .password-policy ul li {
  width: 100%;
  flex: 50%;
  font-size: 18px;
  line-height: 27px;
  list-style-type: "- ";
}

.form_main > span {
  display: flex;
  justify-content: flex-start;
  width: 100%;
  position: relative;
}

.slider_wrapper {
  flex-direction: row;
  display: flex;
  justify-content: center;
}

.slider,
.slider_selected {
  border: 1px solid $mlb-color-accent;
  border-radius: 20px;
  width: 70px;
  height: 15px;
  background-color: $mlb-color-background;
  margin: 6px;
  cursor: pointer;
  margin-top: -100px;

  &:hover {
    cursor: not-allowed;
  }
}

.slider_selected {
  background-color: $mlb-color-accent;
}

.hideSlider + .slider_wrapper {
  display: none;
}

.form_main .marketingOptIn {
  margin-top: 30px;
}

.form_main .marketingOptIn span p {
  font-size: 22px;
  line-height: 27px;
  text-align: left;
  margin: 0 0 0 6px;
}

.form_main .marketingOptIn,
.form_main .privacyPolicy {
  display: flex;
  justify-content: flex-start;
}

.form_main .privacyPolicy {
  margin-top: 19px;
}

.form_main .marketingOptIn span h5 {
  margin-left: 6px;
  margin-top: 19px;
  font-size: 22px;
  line-height: 27px;
  text-align: left;
}

.form_main .privacyPolicy span p {
  font-size: 22px;
  line-height: 27px;
  text-align: left;
  margin: 0 0 0 6px;
}

.form_main:nth-child(4) {
  display: flex;
  align-items: center;
  justify-content: center;
}

.form_main:nth-child(4) label {
  width: 50%;
  font-size: 22px;
  line-height: 34px;
}

.form_main .necessary {
  text-align: left;
  width: 100%;
  margin: 27px 0 40px;
}

.form_main .necessary p {
  font-size: 22px;
  line-height: 20px;
  margin: 0;
}

.form_main .marketingOptIn a,
.form_main .privacyPolicy a {
  color: $mlb-color-accent;
  text-decoration-thickness: 1px;

  &:visited {
    font-weight: bold;
  }

  &:hover {
    font-weight: bold;
  }
}

.form_main .marketingOptIn h5 a {
  color: $mlb-color-text;
}

.choose-brand,
.dealer-code {
  font-size: 28px;
  line-height: 32px;
  text-align: center;
  width: 100%;
  color: $color-shade-8;
  font-family: "neuland grotesk condensed light", sans-serif;
  margin-bottom: 40px;
}

.choose-brand p,
.dealer-code p {
  font-size: 28px;
  line-height: 32px;
}

.choose-brand p:first-child {
  margin-bottom: 0;
}

.choose-brand p:nth-child(2) {
  margin-top: 0;
}

.dealer-code p:first-child {
  margin-top: 34px;
  margin-bottom: 0;
}

.dealer-code p:nth-child(2) {
  margin-top: 0;
}

.notice {
  text-align: center;
  font-family: "" neuland grotesk condensed light "", sans-serif;
  line-height: 32px;
  font-size: 28px;
  width: 100%;
  margin-bottom: 40px;

  & h2 {
    font-family: "" neuland grotesk condensed bold "", sans-serif;
    font-size: 32px;
    line-height: 45px;
    margin-bottom: 39px;
    color: $mlb-color-text;
  }

  & p {
    font-family: "" neuland grotesk condensed light "", sans-serif;
    font-size: 20px;
    line-height: 25px;
    color: $mlb-color-text;
  }

  & a {
    text-decoration-thickness: 1px;
    color: $mlb-color-text;
    font-size: 20px;
    line-height: 25px;
  }
}

.error-message {
  color: $mlb-color-accent;
  font-size: 22px;
  padding: 0 5px;
  line-height: 20px;
  display: none;
  text-align: center;
}

.email .error-message,
.phone .error-message,
.confirmPassword .error-message,
.dealerCode .error-message {
  display: block;
}

.phone .error-message {
  margin-left: -50%;
  text-align: left;
}

.email,
.phone,
.confirmPassword,
.dealerCode {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.email {
  margin-bottom: 13px;
}

.dateOfBirth {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-wrap: wrap;

  & .error-message {
    display: block;
    flex-basis: 100%;
  }

  & label {
    font-size: 28px;
    line-height: 34px;
    margin: 0 auto;
    color: $color-shade-5;
  }

  & input {
    position: relative;
  }

  .react-datepicker-wrapper {
    width: 50% !important;
  }

  .react-datepicker-time__header,
  .react-datepicker-year-header,
  .react-datepicker__current-month {
    display: none !important;
  }

  .react-datepicker__header__dropdown
    .react-datepicker__header__dropdown--scroll {
    display: flex !important;
    justify-content: center !important;
  }

  .react-datepicker__year-read-view--down-arrow,
  .react-datepicker__month-read-view--down-arrow {
    margin-top: 3px !important;
  }

  .react-datepicker__year-read-view--selected-year {
    margin-left: 25px !important;
    font-weight: bold;
  }

  .react-datepicker__month-read-view--selected-month {
    font-weight: bold;
  }

  .react-datepicker__year-dropdown-container,
  .react-datepicker__year-dropdown-container--scroll {
    margin-left: 20px !important;
  }

  .react-datepicker__month-read-view,
  .react-datepicker__year-read-view {
    visibility: visible !important;
  }

  .react-datepicker-popper {
    display: none;
  }
}

.invalid {
  border: 1px solid #c9102f !important;
  /*   background: url("./resources/images/invalid.png") no-repeat 97%;
    background-size: 20px 19px; */
}

.valid {
  border: 1px solid #777777;
  /*      background: url("./resources/images/valid.png") no-repeat 97%;
    background-size: 20px 19px;  */
}

.input-icon-valid {
  background-image: url("./resources/images/valid.png");
  background-size: contain;
  background-repeat: no-repeat;
  width: 20px;
  height: 19px;
  position: absolute;
  top: 20px;
  right: 2%;
}

.input-icon-invalid {
  background-image: url("./resources/images/invalid.png");
  background-size: contain;
  background-repeat: no-repeat;
  width: 20px;
  height: 19px;
  position: absolute;
  top: 20px;
  right: 2%;
}

.showPassIcon {
  content: url("./resources/images/hide.png");
  position: absolute;
  width: 20px;
  height: 19px;
  top: 20px;
  right: 10%;
  z-index: 2;
}

.hidePassIcon {
  content: url("./resources/images/unhide.png");
  position: absolute;
  width: 20px;
  height: 19px;
  top: 20px;
  right: 10%;
  z-index: 3;
}

.hidePassIconBtn {
  display: none;
}

.reg-datamatch-wrapper,
.reg-datamatch-err-wrapper,
.reg-datamatch-waiting-wrapper,
.reg-datamatch-abort-wrapper {
  text-align: center;
  word-break: break-word;

  & .form_heading {
    margin: 0;

    & h1,
    h2 {
      margin: 0;
    }
  }
}

.hide-field {
  display: none !important;
}

.dataMatchPrevious {
  margin: 26px 0 30px !important;
}

.dataMatchDesc {
  padding-bottom: 37px;
}

.idMatchDesc {
  width: 100vw;
  margin: 0 -36px 0;
  padding: 40px 36px 0;
  background-color: #f5f5f5;
}

.idMatchDesc2 {
  width: 100vw;
  margin: 0 -36px;
  padding: 0 36px 40px;
  background-color: #f5f5f5;
}

.idMatchUpload-container {
  width: 100vw;
  background: #f5f5f5;
  margin: 0 -36px;
  padding: 0 36px;
}

.idMatchUpload {
  margin: 23px 0 30px !important;
}

.reg-datamatch-waiting {
  & .verificationDesc {
    & p {
      padding-bottom: 20px;
    }
  }

  & .clockIcon {
    width: 62px;
    height: 62px;
    background-image: url("./resources/images/MLB_Clock_icon.png");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    margin-bottom: 40px;
  }
}

.reg-datamatch-abort {
  & .dataMatchExhaustedScreenTitle {
    & p {
      margin-bottom: 26px;
    }
  }

  & .idMatchUpload-container {
    background-color: #fff;

    & .idMatchUpload {
      margin-top: 0 !important;
      margin-bottom: 20px !important;
    }
  }

  & .idMatchAbort {
    margin-top: 0 !important;
    margin-bottom: 40px !important;
    background-color: #d5d5d5 !important;
  }

  & .idMatchAbortDesc {
    width: 100vw;
    margin: 0 -36px;
    padding: 40px 36px 40px;
    background-color: #f5f5f5;
  }
}

.gigya-composite-control-password {
  position: relative;
}

.showPass {
  content: url("./resources/images/hide.png");
  position: absolute;
  width: 20px;
  height: 19px;
  top: 15px;
  right: 2%;
  z-index: 2;
}

.hidePass {
  content: url("./resources/images/unhide.png");
  position: absolute;
  width: 20px;
  height: 19px;
  top: 15px;
  right: 2%;
  z-index: 3;
}

@media screen and (min-width: (($xxs)-1)) {
  .idMatchDesc p {
    word-break: keep-all;
  }
}

@media screen and (min-width: ($xxs+1)) {
  .form_main .password-policy ul li {
    font-size: 21px;
  }
}
