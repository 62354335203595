// .cmp-image {
//   /*   margin-top: 2.25em; */
//   }
//   .cmp-image__link {
//   }
.cmp-image__image {
  display: block;
}
// .cmp-image__title {
// }
