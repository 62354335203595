.unbranded-home-page {
  & #welcome-heading {
    margin: 10px 0 24px;

    & h1 {
      font-family: "neuland grotesk condensed bold", sans-serif;
      font-size: 38px;
      line-height: 45px;
      text-align: center;
      color: $mlb-color-text;
    }
  }

  & #welcome-reg-section {
    & h2 {
      font-family: "helvetica", sans-serif;
      font-size: 16px;
      line-height: 22px;
      color: $mlb-color-text;
    }

    & .cmp-teaser__action-link {
      background-color: $mlb-color-accent;
      color: $mlb-color-text-inverted;
      font-family: "neuland grotesk condensed regular", sans-serif;
      font-size: 22px;
    }
  }

  & #cmp-login-heading p {
    font-family: "neuland grotesk condensed bold", sans-serif;
    font-size: 24px;
    line-height: 32px;
    color: $mlb-color-text;
  }
}
