.horizontal {
  // .cmp-tabs {}

  .cmp-tabs__tablist {
    border-bottom: 0.125em solid $color-shade-1;
  }
  // .cmp-tabs__tab {}
  .cmp-tabs__tab--active {
    border-bottom: 0.125em solid $color-shade-4;
    &:focus {
      border-bottom: 0.125em solid $color-shade-4;
    }
  }

  // .cmp-tabs__tabpanel {}
  // .cmp-tabs__tabpanel--active {}
}
