//== Defaults
html,
body {
  color: $color-shade-8;
  font-size: $font-size;
  font-family: "neuland grotesk condensed light", sans-serif;
  line-height: $font-height;
  background: $mlb-color-background;
  overflow-x: hidden;
}

a {
  color: $mlb-color-accent;

  &:hover {
    color: $mlb-color-accent;
    font-weight: bold;
  }
}

button,
input,
optgroup,
select,
textarea {
  font: inherit;
}

body {
  padding: 25px 25px 0;
  width: 100%;
}

.container {
  margin: 0 auto;
  padding: 0;
}
.grecaptcha-badge {
  bottom: 100px !important;
}
h1 {
  font-family: "neuland grotesk condensed bold", sans-serif;
  font-weight: bold;
  font-size: 40px;
  margin: 0 auto 40px;
  text-align: center;
  line-height: 54px;
  color: $mlb-color-text;
}

#welcome-heading {
  margin: -14px auto 35px;

  & h1 {
    margin-bottom: 0;
    font-size: 40px;
    line-height: 40px;
  }
}

h2 {
  font-size: 28px;
  line-height: 32px;
  font-family: "neuland grotesk condensed light", sans-serif;
  color: $color-shade-8;
}

h3 {
  font-size: 22px;
  margin-top: 20px;
  margin-bottom: 20px;
}

#cmp-login-heading h2 {
  font-family: "neuland grotesk condensed regular", sans-serif;
  font-weight: bold;
  margin-top: 0;
  text-align: center;
}

#cmp-login-heading p {
  font-size: 28px;
  margin-bottom: 15px;
  line-height: 32px;
  text-align: center;
  color: $color-shade-8;
}

#cmp-link {
  & h3 {
    & a {
      color: #777777;
    }
  }
}

p {
  font-size: 28px;
  margin-bottom: 0;
  line-height: 32px;
}

.button {
  background-color: $mlb-color-accent;
  height: 46px;
  border-radius: 200px;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  vertical-align: middle;
}

.cta-back {
  width: 46px;
  height: 46px;
  background: none;
  margin-bottom: 20px;

  & button {
    width: 46px;
    background-image: url(./resources/images/form-back.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 50%;
    border: none;
    height: 46px;
    box-sizing: border-box;
    display: block;
    font-size: 0;
  }
}

.home-page {
  & .cta-back {
    margin-top: 20px;
    margin-left: 25px;
  }
}

.cta-home {
  background-color: $mlb-color-background !important;

  & a {
    width: 46px;
    background-image: url(./resources/images/form-back.png) !important;
    transform: rotate(180deg) !important;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 50%;
    border: none;
    height: 46px;
    box-sizing: border-box;
    display: block;
    font-size: 0;
    margin-left: 0;
  }

  & .cmp-button {
    margin: 0 auto 20px 0;
  }

  & .cmp-button__text {
    display: none;
  }
}

#cmp_cfmreg_image {
  padding: 10px 0;

  & img {
    height: 38px;
    width: 75px;
    margin: 0 auto;
  }
}

#cmp_cfmreg_desc {
  text-align: center;

  & p:first-child {
    margin-top: 18px;
    margin-bottom: 26px;
  }
}

#cmp_termsofuse p {
  font-size: 16px;
  line-height: 20px;
  text-align: left !important;
}

#list-items ul li,
#veratad ul li {
  font-size: 28px;
  line-height: 32px;
  font-family: "neuland grotesk condensed light", sans-serif;
}

.a-screen.portrait {
  width: 100% !important;
  font-size: 22px !important;
  // padding: 0 !important;
  background-color: #4a4a4a !important;
  padding: 0 !important;
}

#cmp_impressum {
  & p a {
    word-break: break-word;
  }
}

#confirm-reg-timestamp {
  text-align: center;
  font-size: 28px;
  line-height: 32px;
  margin-top: 36px;
}

#thankyou-description {
  text-align: center;
}

@media screen and (min-width: ($xxs+1)) {
  h1 {
    font-size: 54px;
  }

  #welcome-heading {
    & h1 {
      font-size: 44px;
      line-height: 44px;
    }
  }
}

$max_col: 12;

// //Default breakpoint
// .aem-Grid {
//   @include generate-grid(default, $max_col);
// }

// // Phone breakpoint
// @media (max-width: 768px) {
//   .aem-Grid {
//     @include generate-grid(phone, $max_col);
//   }

//   a {
//     width: 100%;
//   }
// }
