// .cmp-form-text {
//   display: grid;
//   margin: 0.5em 0.5em;
//   & label {
//     margin-bottom: 0.5625rem;
//     color: $color-shade-3;
//     font-size: 0.625em;
//     text-transform: uppercase;
//   }
// }
// .cmp-form-text__help-block {
// }
// .cmp-form-text__textarea {
//   height: 10em;
//   padding: 0.5625em 1em 0.6875em 1em;
//   font-size: 1em;
//   background-color: $color-shade-1;
//   border: 0.125em solid $color-shade-1;
//   border-radius: 0.25em;
//   &:hover {
//     background-color: $color-background;
//   }
//   &:focus {
//     border-color: $color-accent-lighter;
//   }
//   &:active {
//     color: $color-text;
//   }
// }
// .cmp-form-text__text {
//   height: 1.25em;
//   padding: 0.5625em 1em 0.6875em 1em;
//   font-size: 1em;
//   background-color: $color-shade-1;
//   border-color: $color-shade-1;
//   border-style: solid;
//   border-width: 0.125em;
//   border-radius: 0.25em;
//   &:hover {
//     background-color: $color-background;
//   }
//   &:focus {
//     border-color: $color-accent-lighter;
//   }
//   &:active {
//     color: $color-shade-5;
//   }
//   //&:invalid {
//   //  border-color: $error;
//   //  color: $color-text;
//   //}
//   //&:valid {
//   //  border-color: $success;
//   //  color: $color-text;
//   //}
// }

.cmp-form-text__text,
.form_main input {
  height: 46px;
  width: 100%;
  background-color: #fff;
  border: 1px solid #D8D8D8;
  border-radius: 10px;
  text-align: center;
  color: #777777;
  outline: none;
  font-size: 22px;
}

.cmp-form-text__text:focus {
  border: 1px solid #D8D8D8;
}