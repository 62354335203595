.lm-unbranded {
  & #welcome-heading {
    margin: 10px 0 24px;

    & h1 {
      font-family: "century gothic bold", sans-serif;
      font-size: 32px;
      line-height: 45px;
      text-align: left;
      color: $lm-color-text;

      &::before {
        // content: '';
        width: 32px;
        height: 32px;
        margin-right: 18px;
        background-image: url("./resources/images/icons/icon-welcome.png");
        background-size: cover;
        display: inline-block;
      }
    }
  }

  & #welcome-reg-section {
    & h2 {
      font-family: "helvetica", sans-serif;
      font-size: 16px;
      line-height: 22px;
      color: $lm-color-text;
    }

    & .cmp-teaser__action-link {
      background-color: $lm-color-accent;
      color: $lm-color-text-inverted;
      font-family: "century gothic bold", sans-serif;
      font-size: 22px;
    }
  }

  & #cmp-login-heading p {
    font-family: "century gothic bold", sans-serif;
    font-size: 21px;
    line-height: 32px;
    color: $lm-color-text;
  }
}
