/* .cmp-navigation {}
.cmp-navigation__group {}
.cmp-navigation__item {}
.cmp-navigation__item--active {}
// .cmp-navigation__item--level-0 {}
.cmp-navigation__item-link {} */

nav {
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1;
  background-color: $mlb-color-background !important;
}

.navbar > .container-fluid {
  padding: 0;
}

.navbar {
  padding-bottom: 0;
}

.logout {
  cursor: pointer;
}

@media screen and (max-width: ($sm)) {
  .navbar-brand {
    margin-left: 18px;

    & img {
      width: 250px !important;
      height: auto;
    }
  }

  .navbar-collapse {
    margin-top: 3%;
    font-size: 36px;
    text-align: center;
    font-family: "neuland grotesk condensed bold", sans-serif;
    font-weight: bold;
    line-height: 80px;
    overflow-y: auto;
    height: calc((100vh - 131px));
    background-image: url("./resources/images/nav-bg-img.png");
  }

  .nav-link {
    color: $mlb-color-background !important;

    &:focus {
      text-decoration: underline !important;
    }

    &:hover {
      text-decoration: underline !important;
    }
  }

  .navbar-toggler.collapsed .toggler-icon {
    height: 19px;
    content: url("./resources/images/hamburger-menu-icon.png");
  }

  .navbar-toggler .toggler-icon {
    height: 21px;
    content: url("./resources/images/close-icon.png");
  }

  .navbar-toggler {
    border: none;

    &:focus {
      // outline: none;
      box-shadow: none;
      outline: none;
    }
  }

  .logout {
    margin-top: -10px;
  }
}

@media screen and (max-width: ($xxs)) {
  .navbar-brand {
    margin-left: 18px;

    & img {
      width: 200px !important;
      height: auto;
    }
  }
}

@media screen and (min-width: ($sm+1)) {
  .nav-item {
    display: flex;
  }
}
