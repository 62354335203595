.cmp-unsubscribe {
	display: flex;
	justify-content: space-between;

	& input[type=button] {
		width: 40%;
		margin: 10px;
	}

	& .unsub-btn-redirect {
		color: $mlb-color-accent !important;
		background-color: $mlb-color-background !important;
		border: 1px solid $mlb-color-accent !important;

		&:focus {
			color: $mlb-color-accent;
			background-color: $mlb-color-background;
			border: 1px solid $mlb-color-accent;
		}
	}
}

.unsub-disclaimer p {
	font-size: 28px;
	line-height: 32px;
	text-align: center !important;
	margin-top: 30px;
}