.profile_section-wrapper {
  & .form_heading h1 {
    margin-top: 53px;
  }

  & .form_heading {
    margin: 0;

    h2 {
      text-align: left;
      font-size: 54px;
      line-height: 80px;
      font-family: "neuland grotesk condensed bold", sans-serif;
      position: relative;
      padding-left: 33px;
      color: $mlb-color-text;

      &::before {
        content: "";
        height: 38px;
        width: 9px;
        background-color: $mlb-color-accent;
        position: absolute;
        top: 25%;
        left: 0;
      }
    }
  }

  & .profile_section {
    margin: 0 35px;
    text-align: left;
    border: none;
    font-size: 18px;
    line-height: 40px;
    display: flex;
    flex-direction: column;
    font-family: "neuland grotesk condensed light", sans-serif;

    & p {
      margin: 0;
      text-align: left;
    }

    & div {
      display: flex;
      align-items: center;
      text-align: left;
      // margin: 10px auto 10px 10px;

      & p {
        font-size: 28px;
        line-height: 46px;
      }
    }

    & input[type="button"] {
      width: 100% !important;
      margin-top: 40px !important;
      border: none;
    }

    & label {
      display: none;
    }

    & select {
      margin-top: 40px;
      font-family: "neuland grotesk condensed light", sans-serif;
    }
  }
}

#cmp-contact-info {
  margin-top: 30px;
  background-color: #f5f5f5;
  padding: 25px;
  font-family: "neuland grotesk condensed regular", sans-serif;
  position: relative;

  & p {
    padding-left: 10px;
    font-size: 28px;
    line-height: 26px;
    font-family: "neuland grotesk condensed light", sans-serif;
    margin: 8px 0;

    & b {
      color: $mlb-color-text !important;
    }

    &:first-child {
      font-size: 28px;
      line-height: 32px;
      padding-right: 30%;
      font-family: "neuland grotesk condensed bold", sans-serif;
      margin-top: 0;
      margin-bottom: 18px;
    }
  }

  & a:first-child {
    &::before {
      content: "";
      width: 16px;
      height: 16px;
      background-image: url(./resources/images/phone.png);
      display: inline-block;
      margin-right: 5px;
    }
  }

  & p:last-child {
    & a::before {
      content: "";
      width: 16px;
      height: 16px;
      background-image: url(./resources/images/email.png);
      display: inline-block;
      margin-right: 5px;
    }
  }

  & a {
    color: $mlb-color-text;
    text-decoration: none;
  }

  &::before {
    content: "";
    height: 21px;
    width: 9px;
    background-color: $mlb-color-accent;
    position: absolute;
    top: 30px;
    left: 0;
  }
}

.optIn-wrapper {
  padding: 25px;
  background-color: #f5f5f5;
  margin-top: 40px;
  position: relative;

  & .form_heading:nth-child(1) h1 {
    font-family: "neuland grotesk condensed bold", sans-serif;
    font-size: 28px;
    line-height: 25px;
    text-align: left;
    padding-left: 10px;
    margin-bottom: 16px;
  }

  & .form_heading:nth-child(2) h2 {
    font-family: "neuland grotesk condensed light", sans-serif;
    font-size: 28px;
    line-height: 32px;
    padding-left: 10px;
    text-align: left;
  }

  & .disclaimer {
    padding-left: 10px;
    margin-top: 15px;

    & p {
      font-family: "neuland grotesk condensed light", sans-serif;
      text-align: left;
      font-size: 22px;
      line-height: 27px;
      color: $color-shade-8;
      border-top: 1px solid rgba($color-shade-5, 0.5);
      padding-top: 5px;
    }
  }

  &::before {
    content: "";
    height: 21px;
    width: 9px;
    background-color: $mlb-color-accent;
    position: absolute;
    // top: 25px;
    left: 0;
  }

  // START: opt in toggle
  & .optIn > span {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between !important;
    padding: 0 40px 0 10px;

    & p {
      font-size: 28px;
      line-height: 26px;
      font-family: "neuland grotesk condensed regular", sans-serif;
      margin-top: 10px;
      color: $mlb-color-text;
    }

    & input[type="checkbox"] {
      appearance: none;
      -webkit-appearance: none;
      -webkit-tap-highlight-color: transparent;
      position: relative;
      border: 0;
      outline: 0;
      cursor: pointer;
      background-color: #f5f5f5;
    }

    & input[type="checkbox"]:after {
      content: "";
      width: 60px;
      height: 32px;
      display: inline-block;
      background: rgba(196, 195, 195, 0.55);
      border-radius: 18px;
      clear: both;
    }

    & input[type="checkbox"]:before {
      content: "";
      width: 28px;
      height: 28px;
      display: block;
      position: absolute;
      left: 0;
      top: 2px;
      border-radius: 50%;
      background: rgb(255, 255, 255);
      box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.6);
    }

    & input[type="checkbox"]:checked:before {
      left: 2px;
      box-shadow: -1px 1px 3px rgba(0, 0, 0, 0.6);
    }

    & input[type="checkbox"]:not(:checked)::before {
      left: 30px;
    }

    & input[type="checkbox"]:checked:after {
      background: #06c32c;
    }
  }

  // END: opt-in toggle
}

// @media screen and (min-width: ($xxs+1)) {
//   .optIn-wrapper {
//     & .form_heading:nth-child(1) h1{
//       font-size: 25px;
//     }
//   }
// }
