// .cmp-button {
// width: fit-content;
// height: fit-content;
// margin: 0.5em 0.5em;
// padding: 0.5625em 1em 0.6875em 1em;
// border-style: solid;
// border-width: 0.125em;
// border-radius: 1.56em;
// height: 46pt;
//   margin-top: 100px;
// }
// .cmp-button__text {
//   font-weight: 300;
//   text-decoration: none;
// }
// .cmp-button__icon {
// }

//== Grid

.cmp-button {
  width: 100%;
  // background-color: $mlb-color-accent;
  font-weight: 300;
  text-decoration: none;
  color: $mlb-color-background;
  margin: 9px auto 5px;
}

.cmp-button__text {
  font-weight: 300;
  text-decoration: none;
  font-size: 22px;
  //text-transform: uppercase;
  font-family: "neuland grotesk condensed regular", sans-serif;
}
