.quiz-page {
  margin-top: 47px;
  // background-repeat: no-repeat;
  // background-size: cover;
  padding: 0;
  background: transparent;

  & input[type="button"] {
    border: none;
    background-color: $mlb-color-background;
    color: $mlb-color-text;
    margin: 0 auto;
    width: 50%;
    font-family: "neuland grotesk condensed regular", sans-serif;
    font-size: 22px;
    line-height: 34px;
    padding: 0 100px;
  }

  & .popup {
    background-color: $mlb-color-accent;
    color: $mlb-color-text-inverted;
    width: 75%;

    & h1 {
      font-size: 42px;
      line-height: 48px;
      margin: 0 auto 26px;
      color: $mlb-color-text-inverted;
    }

    & .popup-btn {
      background-color: $mlb-color-background;
      color: $mlb-color-text;
      width: fit-content;
      padding: 0 24px !important;
      font-size: 22px;
      line-height: 34px;
    }

    & p {
      font-size: 21px;
      line-height: 25px;
    }
  }

  .freestyle-form-container {
    background-image: url("./resources/images/icons/mlb_branded/bg-mlb-quiz-jan-2024.png");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    padding: 30px 21px;
    & .freestyle__form-container {
      padding: 50px 40px;
      border-radius: 12px;
      opacity: 1;
      position: relative;
      margin-bottom: 50px;

      &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        z-index: 0;
        border-radius: 12px;
        backdrop-filter: blur(15px);
        -webkit-backdrop-filter: blur(15px);
      }
    }

    & .form_heading {
      word-break: keep-all !important;
      z-index: 1;
      position: relative;
      text-align: center;

      & h1 {
        font-family: "neuland grotesk condensed regular", sans-serif;
        font-size: 38px;
        line-height: 38px;
        text-align: center;
        color: $mlb-color-text-inverted;
        margin: 33px auto 25px;
      }

      & h2 {
        font-family: "neuland grotesk condensed light", sans-serif;
        font-size: 21px;
        line-height: 25px;
        text-align: center;
        color: $mlb-color-text-inverted;
        margin: 0 auto 28px;
      }
    }

    & .cmp_quiz {
      & p a {
        font-family: "neuland grotesk condensed light", sans-serif;
        font-size: 32px;
        line-height: 25px;
        color: $mlb-color-text;
        text-decoration: underline;
      }
    }

    & .answers {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 30px;

      & span {
        display: flex;
        justify-content: center;
        align-items: center;
        border: none;
        width: 100vw;
        position: relative;
        padding: 21px 0;
        height: auto;
        text-align: center;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;

        // background: linear-gradient(0deg, rgba(0,0,0,0.2) 0%, rgba(255,255,255,0.2) 100%);
        &:nth-of-type(1) {
          background-image: none;
        }

        &:nth-of-type(2) {
          background-image: none;
        }

        &:nth-of-type(3) {
          background-image: none;
        }

        & input[type="radio"] {
          background-color: unset;
          border: none;
          width: 100%;
          height: 100%;
          position: absolute;
          margin: 0;
          border-radius: 0;
        }

        & label {
          color: $mlb-color-text;
          background-color: $mlb-color-background;
          padding: 8px 24px;
          border-radius: 10px;
          font-family: "neuland grotesk condensed regular", sans-serif;
          font-size: 21px;
          margin-top: auto;
          text-align: center;
          width: 50%;
        }
      }
    }

    & .btn-quiz-tc {
      text-align: center;
      position: absolute;
      bottom: -35px;

      & p {
        & a {
          font-family: "neuland grotesk condensed light", sans-serif;
          font-size: 26px;
          line-height: 25px;
          color: $mlb-color-text-inverted !important;
          text-decoration: underline;
        }
      }
    }
  }

  & .cmp_wrong_ans {
    flex-direction: column;
    position: relative;

    & .cmp_desc_wrongans p {
      width: 100%;
      color: $mlb-color-text-inverted;
      text-align: center;
      margin-bottom: 25px;
      font-family: "neuland grotesk condensed light", sans-serif;
      font-size: 25px;
      line-height: 25px;
    }

    & .cmp_head_wrongans h2 {
      width: 100%;
      color: $mlb-color-text-inverted;
      text-align: center;
      margin-bottom: 26px;
      font-family: "neuland grotesk condensed bold", sans-serif;
      font-size: 42px;
      line-height: 48px;
    }
  }

  & .cmp_correct_ans-wrapper {
    & .cmp_correct_ans {
      flex-direction: column;
      position: relative;

      & .cmp_desc_rightans p {
        font-family: "neuland grotesk condensed light", sans-serif;
        font-size: 25px;
        line-height: 25px;
        width: 100%;
        color: $mlb-color-text-inverted;
        text-align: center;
        margin-bottom: 60px;
      }

      & .cmp_head_rightans h2 {
        font-family: "neuland grotesk condensed bold", sans-serif;
        font-size: 42px;
        line-height: 48px;
        width: 100%;
        color: $mlb-color-text-inverted;
        text-align: center;
        margin-bottom: 26px;
      }
    }
  }

  & #main-footer {
    & .cmp-text p a {
      color: $mlb-color-text;
    }

    & #ot-sdk-btn {
      color: $mlb-color-text !important;
      background-color: transparent !important;
    }

    & .cmp-title__text {
      color: $mlb-color-text;
    }

    & .cmp-separator__horizontal-rule {
      margin-bottom: 0;
    }
  }

  .slider_wrapper {
    display: none;
  }
}

.quiz_ans_bg {
  & .quiz-ans-img {
    margin-top: 50px;
    width: 100%;
    height: 0;
    padding-bottom: 100%;
    background-image: url("./resources/images/june_quiz/quiz_ans_img.png");
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
  }

  & #main-footer {
    & .cmp-text p a {
      color: $mlb-color-text;
    }

    & #ot-sdk-btn {
      color: $mlb-color-text;
      background-color: transparent !important;
    }

    & .cmp-title__text {
      color: $mlb-color-text-inverted;
    }
  }
}

#cmp_participation_head {
  margin: 0 25px 30px;
  text-align: center;

  & h1 {
    font-size: 30px;
  }
}

.full_width_page_text {
  margin: 0 20px;
  text-align: left;

  & a {
    word-break: break-word;
  }
}

#quiz-bg-img img {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: -1;
  margin-top: 47px;
}

@media screen and (min-width: ($sm + 1)) {
  #cmp_participation_head {
    & h1 {
      font-size: 54px;
    }
  }
}
