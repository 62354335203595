.f6-unbranded {
    & .freestyle__form-container {
        position: relative;
    }

    & .slider_wrapper {
        height: 2px;
        width: 100%;
        background-color: #3c3c3c;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        display: flex;
        position: absolute;
        top: 0;
    }

    & .slider {
        height: 2px;
        cursor: pointer;
        width: calc(100% / 5);
        margin: 0;
        background-color: #000;
        border: none;
    }

    & .slider_selected {
        height: 5px;
        width: calc(100% / 5);
        background-color: #000;
        border: none;
        margin: 0;
        border-radius: 0;
    }

    .hideSlider+.slider_wrapper {
        display: none;
    }
}