@mixin headingIcon ($url) {
    background-image: url($url);
}

@mixin mlbDropdown($caretUrl) {
    text-align: left;
    text-align-last: left;
    background: url($caretUrl) 95% no-repeat;
    background-size: 20px 20px;
}
@mixin redlandInfoHeadingBg{
    background: linear-gradient(126deg, rgba(112,25,28,1) 0%, rgba(114,25,28,1) 35%, rgba(207,46,51,1) 100%);
}
@mixin redlandInfoDescBg{
    background: linear-gradient(126deg, rgba(145,0,1,1) 0%, rgba(145,0,1,1) 35%, rgba(145,0,1,1) 100%);
}