@import "../../mixins/_mixins.scss";

.f6-branded {
  & .profile_section-wrapper {
    margin: 35px 25px 0;

    & .form_heading {
      & h1 {
        font-family: "patua one", sans-serif;
        font-size: 32px;
        line-height: 45px;
        text-align: left;
        margin: 10px 0 35px;
        padding: 0;
        position: relative;
        padding-left: 50px;

        &::before {
          content: "";
          width: 32px;
          height: 32px;
          background-size: cover;
          display: inline-block;
          position: absolute;
          top: 5px;
          left: 0;
          @include headingIcon("./resources/images/icons/icon-profile.png");
        }
      }

      & h2 {
        margin: 0;

        &::before {
          content: "";
          display: none;
          background-color: transparent;
        }
      }
    }

    & .profile_section {
      margin: 0;
      font-family: "helvetica", sans-serif;

      & div {
        & p {
          font-size: 16px;
          line-height: 28px;
        }
      }

      & label {
        display: block;
        margin-top: 30px;
        font-family: "patua one", sans-serif;
        font-size: 16px;
        line-height: 20px;
      }

      & select {
        margin-top: 10px;
        padding: 10px;
        font-family: "helvetica", sans-serif;
        font-size: 15px;
        line-height: 22px;
        @include f6Dropdown("./resources/images/icons/icon-dropdown-caret.png");
      }

      & input[type="button"] {
        margin-top: 35px !important;
        background-color: $f6-color-accent;
        font-family: "patua one", sans-serif;
        font-size: 22px;
        line-height: 34px;
      }
    }
  }

  & .optIn-wrapper {
    background-color: transparent;

    &::before {
      content: "";
      display: none;
      background-color: transparent;
    }

    & .form_heading {
      margin: 0;

      & h1 {
        padding-left: 0;
        font-family: "patua one", sans-serif;
        font-size: 21px;
        line-height: 32px;
        text-align: left;
        margin-bottom: 25px;
      }

      & h2 {
        margin: 0;
      }
    }

    & .optIn {
      & .profileOptInDescription {
        margin-bottom: 28px;

        & p,
        b {
          font-family: "helvetica", sans-serif;
          font-size: 16px;
          line-height: 22px;
          text-align: left;
        }
      }

      & > span {
        padding: 16px 40px 16px 0;
        align-items: center;

        & input[type="checkbox"] {
          background-color: transparent;
        }

        & span p {
          font-family: "patua one", sans-serif;
          font-size: 18px;
          line-height: 32px;
        }
      }

      & .disclaimer {
        padding: 0;
        margin-top: 28px;

        & p {
          font-family: "helvetica", sans-serif;
          font-size: 16px;
          line-height: 22px;
          text-align: left;
          border: none;
        }
      }
    }
  }

  & #cmp-contact-info {
    background-color: $f6-color-shade-grey;

    &::before {
      content: "";
      background-color: transparent;
      display: none;
    }

    & h3 {
      font-family: "patua one", sans-serif;
      font-size: 21px;
      line-height: 32px;
      text-align: left;
    }

    & p {
      font-family: "helvetica", sans-serif;
      font-size: 16px;
      // line-height: 22px;
      text-align: left;
      padding: 0;
      background-repeat: no-repeat;
      background-position: left center;
      padding-left: 45px;
      vertical-align: middle;
      line-height: normal;
      background-size: 20px 20px;

      &:first-of-type {
        background-image: url("./resources/images/icons/f6_branded/icon-phone-f6-blue.png");

        & a::before {
          content: "";
          background: transparent;
          display: none;
        }
      }

      &:last-of-type {
        background-image: url("./resources/images/icons/f6_branded/icon-email-f6-blue.png");

        & a::before {
          content: "";
          background: transparent;
          display: none;
        }
      }
    }
  }
}
