.f6-branded,
.f6-quiz {
  margin-top: 67px;

  & nav {
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1;
    background-color: $f6-color-background !important;
  }

  & .navbar > .container-fluid {
    padding: 0;
  }

  & .navbar {
    padding: 8px 0;
  }

  & .logout {
    cursor: pointer;
  }

  @media screen and (max-width: ($sm)) {
    .navbar-brand {
      margin-left: 18px;

      & img {
        width: 150px !important;
        height: auto;
      }
    }

    .navbar-collapse {
      margin-top: 3%;
      font-size: 32px;
      text-align: center;
      font-family: "patua one", sans-serif;
      font-weight: bold;
      line-height: 80px;
      overflow-y: auto;
      height: calc((100vh - 131px));
      background-image: url("./resources/images/icons/f6_branded/navbar-bg-img-f6-menu.jpg");
    }

    .nav-link {
      color: $color-background !important;

      &:focus {
        text-decoration: underline !important;
      }

      &:hover {
        text-decoration: underline !important;
      }
    }

    .navbar-toggler.collapsed .toggler-icon {
      height: 30px;
      content: url("./resources/images/icons/f6_branded/icon-hamburger-menu.png");
    }

    .navbar-toggler .toggler-icon {
      height: 22px;
      content: url("./resources/images/icons/f6_branded/icon-hamburger-menu-colse.png");
    }

    .navbar-toggler {
      border: none;

      &:focus {
        // outline: none;
        box-shadow: none;
        outline: none;
      }
    }

    .logout {
      margin-top: -10px;
    }
  }

  @media screen and (min-width: ($m)) {
    .navbar-brand {
      margin-left: 8px;

      & img {
        width: 200px !important;
        height: auto;
      }
    }
  }

  @media screen and (min-width: ($sm+1)) {
    .nav-item {
      display: flex;
    }
  }
}
