#cmp-stickyFooter {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 84px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: $color-shade-6;
  background-color: $mlb-color-background;
  z-index: 9;

  & p {
    font-size: 20px;
    line-height: 32px;
    font-family: "helvetica", sans-serif;
  }
}

#cmp-stickyFooter::before {
  content: "";
  width: 100%;
  height: 2px;
  background-image: url(./resources/images/separator-img.png);
  position: absolute;
  top: 0;
  left: 0;
}

.container {
  padding: 0;
}
