.home-page {
    padding: 0;
    & #marlboroHomeContainer {
        position: relative;

        & #quizPopup, & #iamMlbCompletionPopup {
            display: none;
            position: fixed;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: rgba(0, 0, 0, 0.5);
            backdrop-filter: blur(2px);
            z-index: 9;
            & > div {
                width: 80%;
                height: auto;
                text-align: center;
                z-index: 9;
                // background-color: $mlb-color-accent;
                background: url("./resources/images/icons/mlb_branded/bg-i-am-mlb-popup.png");
                background-position: center;
                background-repeat: no-repeat;
                background-size: cover;
                border: none;
                border-radius: 5px;
                padding-bottom: 27px;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                padding: 30px 20px;
                & h2 {
                    font-family: "hackney", sans-serif;
                    font-size: 40px;
                    line-height: 42px;
                    color: $color-text-inverted;
                    margin-top: 0;
                    margin-bottom: 20px;
                }
                & p,
                b {
                    font-family: "helvetica", sans-serif;
                    font-size: 16px;
                    line-height: 20px;
                    color: $color-text-inverted;
                }
                & .button {
                    width: fit-content;
                    margin: 50px auto 0;
                    background-color: $color-background;
                    padding: 0 20px;
                    & #popupButton, #completionPopupCta {
                        background-color: transparent;
                        margin: 0;
                        padding: 0 20px;
                        & span {
                            color: $mlb-color-text;
                            font-family: "hackney", sans-serif;
                            font-size: 17px;
                            line-height: 18px;
                        }
                    }
                }
            }
        }
    }
    & #iAmUnlimitedContainer {
        background: $color-foreground;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        padding: 20px 0px;
        & h2 {
            font-family: "hackney", sans-serif;
            color: $color-text-inverted;
        }
        & p {
            font-family: "helvetica", sans-serif;
            font-size: 16px;
            line-height: 20px;
            color: $mlb-color-text-inverted;
            & b {
                font-family: "helvetica bold", sans-serif;
                color: $mlb-color-text-inverted;
            }
        }
        & #i_am_unlimited_heading_start {
            font-family: "hackney", sans-serif;
            & h2 {
                font-size: 44px;
                line-height: 40px;
                margin: 10px auto 30px;
            }
            & p {
                font-size: 16px;
            line-height: 20px;
                margin: 0 20px;
            }
        }
        & #desc_cooking_session,
        #desc_taste_tour,
        #desc_mixology_class {
            & h2 {
                margin: 0 auto 20px;
                font-size: 60px;
                line-height: 62px;
            }
            & p {
                font-size: 16px;
            line-height: 20px;
                margin: 0 20px 20px;
            }
        }
        & #i_am_unlimited_desc {
            margin: 10px 20px;
            h3 {
                font-family: "hackney", sans-serif;
                font-size: 44px;
                line-height: 42px;
                color: $color-text-inverted;
                margin: 20px 0 0;
            }
            & p {
                font-family: "helvetica", sans-serif;
                font-size: 17px;
                line-height: 17px;
            }
        }

        & .btn-quiz {
            margin: 30px auto 10px;
            background: $mlb-color-accent;
            width: fit-content;
            padding: 10px 50px;
            position: relative;
            & button {
                background: transparent;
                border: none;

                & span {
                    font-family: "helvetica bold", sans-serif;
                    font-size: 16px;
                    line-height: 19px;
                }

                &:hover {
                    color: $mlb-color-text-inverted;
                }
            }
        }
        & #home-quiz-tc-link,
        #home-quiz-tc-link-bottom {
            margin: 10px auto;
            text-align: center;

            & p {
                & a {
                    font-family: "helvetica", sans-serif;
                    font-size: 15px;
                    line-height: 17px;
                    color: $mlb-color-text-inverted;
                    text-decoration: underline;
                }
            }
        }
    }
    & #home-page-banner {
        position: relative;
        & .countdown {
            position: absolute;
            bottom: 10px;
            right: 10px;
            text-align: center;
            padding: 0 10px;
            & h2 {
                margin: 0;

                color: $color-text-inverted;
            }
            & .days-left {
                font-size: 55px;
                font-family: "eurostile lt bold", sans-serif;
            }
            & .days-to-go {
                font-size: 14px;
                font-family: "eurostile lt regular", sans-serif;
            }
        }
    }

    & #home-page-heading1 {
        & h1 {
            font-family: "neuland grotesk condensed bold", sans-serif;
            font-size: 34px;
            line-height: 39px;
            text-align: left;
            color: $mlb-color-accent;
            padding: 20px 0 10px 21px;
            margin: 0;
        }
    }

    & #home-page-heading2 {
        & h3 {
            font-family: "neuland grotesk condensed bold", sans-serif;
            font-size: 22px;
            line-height: 25px;
            text-align: left;
            color: $mlb-color-text;
            padding: 10px 0 10px 21px;
            margin: 0;
        }
    }

    & #home-page-description1 {
        & p {
            font-family: "neuland grotesk condensed light", sans-serif;
            font-size: 21px;
            line-height: 25px;
            text-align: left;
            color: $mlb-color-text;
            padding: 10px 10px 20px 21px;
            margin: 0;
        }
    }

    & #home-page-description2 {
        & p {
            font-family: "neuland grotesk condensed light", sans-serif;
            font-size: 18px;
            line-height: 25px;
            margin: 30px 0 15px 21px;
            color: $mlb-color-text;
        }
    }

    & .btn-quiz {
        // margin-top: 40px;
        background: $mlb-color-accent;
        width: fit-content;
        padding: 10px 50px;
        margin: 10px auto 30px;

        & #quiz-cta {
            background: transparent;
            border: none;

            & span {
                font-family: "neuland grotesk condensed regular", sans-serif;
                font-size: 22px;
                line-height: 34px;
            }

            &:hover {
                color: $mlb-color-text-inverted;
            }
        }
    }

    & .overlay-container {
        & #home-page-overlay {
            background-color: $mlb-color-background;
            border: none;
            border-radius: 5px;

            & #homepage-overlay-heading h2 {
                font-family: "neuland grotesk condensed bold", sans-serif;
                font-size: 24px;
                line-height: 25px;
                text-align: center;
                margin: 0 auto 35px;
            }

            & #dealercode-and-timestamp p,
            b {
                font-family: "helvetica", sans-serif;
                font-size: 16px;
                line-height: 22px;
            }

            & button {
                background-color: $mlb-color-accent;
                width: 90%;

                & span {
                    font-family: "neuland grotesk condensed regular", sans-serif;
                    font-size: 22px;
                    line-height: 34px;
                }
            }
        }
    }
}
