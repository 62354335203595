@import "../../mixins/_mixins.scss";

.f6-unbranded {
  & .cmp-unsubscribe-wrapper {
    & .form_heading {
      &:first-of-type {
        padding: 10px 0 39px;

        & h1::before {
          @include headingIcon("./resources/images/icons/icon-logout.png");
        }
      }

      & h2 {
        font-size: 16px;
        line-height: 22px;
        font-family: "helvetica", sans-serif;
        margin-bottom: 35px;
      }
    }

    & .cmp-unsubscribe {
      & .unsub-btn-redirect {
        color: $f6-color-accent !important;
        background-color: $f6-color-background !important;
        border: 1px solid $f6-color-accent !important;
      }
    }
  }
}
