.lm-quiz {
  // background-image: url('./resources/images/quiz_3_q_bg.png');
  // background-repeat: no-repeat;
  // background-size: cover;
  padding: 0;
  background: transparent;

  & input[type="button"] {
    border: none;
    color: $color-text-inverted;
    background: linear-gradient(
      180deg,
      rgba(236, 19, 94, 1) 0%,
      rgba(178, 0, 42, 1) 100%
    );
    margin: 0 auto;
    width: 100%;
    font-family: "century gothic bold", sans-serif;
    font-size: 22px;
    line-height: 34px;
    text-align: center;
  }

  .cmp_quiz-wrapper {
    background-image: url("./resources/images/icons/lm_branded/bg-quiz-lm-70-years.jpg");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    padding: 20px;

    & .form_heading {
      // word-break: keep-all !important;

      & h1 {
        font-family: "century gothic", sans-serif;
        font-size: 32px;
        line-height: 39px;
        text-align: center;
        color: $lm-color-text-inverted;
        margin: 0 auto 30px;
        word-break: keep-all;
      }

      & h2 {
        font-family: "helvetica", sans-serif;
        font-size: 19px;
        line-height: 22px;
        text-align: center;
        color: $lm-color-text-inverted;
        margin: 0 auto 28px;
      }
    }

    & .answers {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 30px;

      & .action_submitQuiz {
        width: 100%;
      }

      & span {
        display: flex;
        justify-content: center;
        align-items: center;
        border: none;
        width: 100%;
        position: relative;
        padding: 21px 0;
        text-align: center;

        & input[type="radio"] {
          background-color: unset;
          border: none;
          width: 100%;
          height: 100%;
          position: absolute;
          margin: 0;
          border-radius: 0;
        }

        & label {
          color: $lm-color-text-inverted;
          background: linear-gradient(
            180deg,
            rgba(236, 19, 94, 1) 0%,
            rgba(178, 0, 42, 1) 100%
          );
          padding: 12px 0;
          border-radius: 200px;
          font-family: "century gothic bold", sans-serif;
          font-size: 22px;
          margin-top: auto;
          text-align: center;
          width: 100%;
        }
      }
    }

    & .btn-quiz-tc {
      margin-top: 150px;
      text-align: center;

      & p {
        & a {
          font-family: "century gothic", sans-serif;
          font-size: 24px;
          line-height: 25px;
          color: $lm-color-text-inverted;
          text-decoration: underline;
        }
      }
    }
  }

  & .cmp_wrong_ans-wrapper {
    background-image: url("./resources/images/icons/lm_branded/bg-quiz-wrong-answer-lm-70-years.jpg");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    padding: 30px;

    & .cmp_wrong_ans {
      flex-direction: column;

      & .cmp_head_wrongans h2 {
        width: 100%;
        color: $lm-color-text-inverted;
        text-align: center;
        margin-bottom: 30px;
        font-family: "century gothic bold", sans-serif;
        font-size: 32px;
        line-height: 39px;
      }

      & .cmp_desc_wrongans p {
        width: 100%;
        color: $lm-color-text-inverted;
        text-align: center;
        margin-bottom: 40px;
        font-family: "helvetica", sans-serif;
        font-size: 21px;
        line-height: 29px;
      }

      & .btn-quiz-tc {
        margin-top: 150px;
        text-align: center;

        & p {
          & a {
            font-family: "century gothic", sans-serif;
            font-size: 24px;
            line-height: 25px;
            color: $lm-color-text-inverted;
            text-decoration: underline;
          }
        }
      }
    }
  }

  & .cmp_correct_ans-wrapper {
    background-image: url("./resources/images/icons/lm_branded/bg-quiz-correct-answer-firework.gif");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    padding: 30px;

    & .cmp_correct_ans {
      flex-direction: column;

      & .cmp_head_correctans h2 {
        font-family: "century gothic bold", sans-serif;
        font-size: 32px;
        line-height: 39px;
        width: 100%;
        color: $lm-color-text-inverted;
        text-align: center;
        margin-bottom: 30px;
      }

      & .cmp_desc_correctans p {
        font-family: "helvetica", sans-serif;
        font-size: 21px;
        line-height: 25px;
        width: 100%;
        color: $lm-color-text-inverted;
        text-align: center;
        margin-bottom: 75px;
      }

      & .btn-quiz-tc {
        margin-top: 150px;
        text-align: center;

        & p {
          & a {
            font-family: "century gothic", sans-serif;
            font-size: 24px;
            line-height: 25px;
            color: $lm-color-text-inverted;
            text-decoration: underline;
          }
        }
      }
    }
  }

  & #main-footer {
    & .cmp-text p a {
      color: $color-text;
    }

    & #ot-sdk-btn {
      color: $color-text !important;
      background-color: transparent !important;
    }

    & .cmp-title__text {
      color: $color-text;
    }

    & .cmp-separator__horizontal-rule {
      margin-bottom: 0;
    }
  }

  .slider_wrapper {
    display: none;
  }
}
