@import "./components/index";

.unbranded-home-page {
  padding: 25px 25px 0;

  & h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: "neuland grotesk condensed bold", sans-serif;
    color: $mlb-color-text;
  }

  & p,
  a,
  ul,
  li,
  input {
    font-family: "helvetica", sans-serif;
    color: $mlb-color-text;
  }

  & p {
    font-size: 16px;
    line-height: 22px;
  }

  & p a {
    color: $mlb-color-accent;
  }

  & p b {
    color: $mlb-color-text;
    font-weight: bold;
    font-family: inherit;
  }

  // terms of use page
  & #cmp_termsofuse_head h1 {
    font-size: 38px;
    line-height: 45px;
    margin: 0 0 25px;
    text-align: center;
    font-family: "neuland grotesk condensed bold", sans-serif;
  }

  // confirm registration page
  & #confirm-registration-heading {
    position: relative;
    padding-top: 43px;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      height: 5px;
      width: 100%;
      background-color: $mlb-color-text;
    }

    & h1 {
      font-size: 38px;
      line-height: 45px;
      text-align: center;
      margin: 0;
      padding: 0;
      position: relative;
    }
  }

  & #cmp_cfmreg_desc {
    & p {
      margin: 40px auto 0;
      padding: 0 8px;
      margin-bottom: 113px;
    }
  }

  // impressum page
  & #cmp_impressum {
    & h1 {
      text-align: center;
      font-size: 38px;
      line-height: 45px;
      margin-bottom: 40px;
    }
  }

  // marketing communication page
  & #cmp_marketing_communication {
    & h1 {
      text-align: center;
      font-size: 38px;
      line-height: 45px;
      margin-bottom: 40px;
    }
  }

  // age verification info page
  & #cmp_age_verification_info {
    & h1 {
      text-align: center;
      font-size: 38px;
      line-height: 45px;
      margin-bottom: 40px;
    }
  }

  // thank you header
  #mlb-thank-you-header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    background-color: $mlb-color-background;
    padding: 13px 16px;

    & #mlb-thank-you-logo {
      & img {
        width: auto;
        height: 20px;
      }
    }
  }

  // thank you page (DOI Successful)
  & #thankyou-success {
    margin-top: 90px;

    & #thankyou-description {
      & h1 {
        font-size: 38px;
        line-height: 45px;
      }
    }
  }

  // thank you page (DOI Expired)
  & #thankyou-expired-link {
    margin-top: 90px;

    & #thankyou-heading {
      & h1 {
        font-size: 38px;
        line-height: 45px;
      }

      & p {
        text-align: center;
      }
    }
  }
}

.home-page {
  .cmp-video {
    width: 100%;
    margin: 0 auto;
    z-index: 0;
    background-color: $color-foreground;

    & iframe {
      width: 100%;
      // border-radius: 10px;

      & html,
      body,
      .player-design,
      #player,
      .video-display {
        background-color: transparent !important;
      }
    }
  }

  & #cmp_participation_head {
    & h1 {
      font-family: "neuland grotesk condensed bold", sans-serif;
      font-size: 25px;
      margin-bottom: 10px;
    }

    & p {
      & b {
        font-family: "helvetica bold", sans-serif;
        font-size: 16px;
        line-height: 22px;
      }
    }
  }

  & #cmp_participation_body {
    & p {
      font-family: "helvetica", sans-serif;
      font-size: 16px;
      line-height: 22px;

      & a {
        color: $mlb-color-accent;
      }
    }
  }
}
