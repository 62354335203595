.quiz-page {
  margin-top: 47px;
  // background-image: url('./resources/images/quiz_3_q_bg.png');
  // background-repeat: no-repeat;
  // background-size: cover;
  padding: 0 20px 0;
  background: transparent;

  & input[type="button"] {
    border: none;
    background-color: $mlb-color-background;
    color: $mlb-color-text;
    margin: 0 auto;
    width: 50%;
    font-family: "neuland grotesk condensed regular", sans-serif;
    font-size: 22px;
    line-height: 34px;
  }

  & .popup {
    background-color: $mlb-color-accent;
    color: $mlb-color-text-inverted;
    width: 75%;

    & h1 {
      font-size: 42px;
      line-height: 48px;
      margin: 0 auto 26px;
      color: $mlb-color-text-inverted;
    }

    & .popup-btn {
      background-color: $mlb-color-background;
      color: $mlb-color-text;
      width: fit-content;
      padding: 0 24px !important;
      font-size: 22px;
      line-height: 34px;
    }

    & p {
      font-size: 21px;
      line-height: 25px;
    }
  }

  .cmp_quiz-wrapper {
    & .form_heading {
      word-break: keep-all !important;

      & h1 {
        font-family: "neuland grotesk condensed bold", sans-serif;
        font-size: 42px;
        line-height: 48px;
        text-align: left;
        color: $mlb-color-text;
        margin: 0 auto 25px;
      }

      & h2 {
        font-family: "neuland grotesk condensed light", sans-serif;
        font-size: 21px;
        line-height: 25px;
        text-align: left;
        color: $mlb-color-text;
        margin: 0 auto 28px;
      }
    }

    & .cmp_quiz {
      & p a {
        font-family: "neuland grotesk condensed light", sans-serif;
        font-size: 32px;
        line-height: 25px;
        color: $mlb-color-text;
        text-decoration: underline;
      }
    }

    & .answers {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 30px;

      & span {
        display: flex;
        justify-content: center;
        align-items: center;
        border: none;
        width: 100vw;
        position: relative;
        padding: 21px 0;
        height: 185px;
        text-align: center;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;

        // background: linear-gradient(0deg, rgba(0,0,0,0.2) 0%, rgba(255,255,255,0.2) 100%);
        &:nth-of-type(1) {
          background-image: url("./resources/images/june_quiz/abenteuer.png");
        }

        &:nth-of-type(2) {
          background-image: url("./resources/images/june_quiz/freiheit.png");
        }

        &:nth-of-type(3) {
          background-image: url("./resources/images/june_quiz/verkehrsstau.png");
        }

        & input[type="radio"] {
          background-color: unset;
          border: none;
          width: 100%;
          height: 100%;
          position: absolute;
          margin: 0;
          border-radius: 0;
        }

        & label {
          color: $mlb-color-text;
          background-color: $mlb-color-background;
          padding: 8px 24px;
          border-radius: 200px;
          font-family: "neuland grotesk condensed bold", sans-serif;
          font-size: 21px;
          margin-top: auto;
          text-align: center;
        }
      }
    }
  }

  & .cmp_wrong_ans {
    flex-direction: column;

    & .cmp_desc_wrongans p {
      width: 100%;
      color: $mlb-color-text-inverted;
      text-align: center;
      margin-bottom: 25px;
      font-family: "neuland grotesk condensed light", sans-serif;
      font-size: 32px;
      line-height: 32px;
    }

    & .cmp_head_wrongans h2 {
      width: 100%;
      color: $mlb-color-text-inverted;
      text-align: center;
      margin-bottom: 26px;
      font-family: "neuland grotesk condensed bold", sans-serif;
      font-size: 42px;
      line-height: 48px;
    }
  }

  & .cmp_correct_ans {
    flex-direction: column;

    & .cmp_desc_rightans p {
      font-family: "neuland grotesk condensed light", sans-serif;
      font-size: 32px;
      line-height: 32px;
      width: 100%;
      color: $mlb-color-text-inverted;
      text-align: center;
      margin-bottom: 25px;
    }

    & .cmp_head_rightans h2 {
      font-family: "neuland grotesk condensed bold", sans-serif;
      font-size: 42px;
      line-height: 48px;
      width: 100%;
      color: $mlb-color-text-inverted;
      text-align: center;
      margin-bottom: 26px;
    }
  }

  & #main-footer {
    & .cmp-text p a {
      color: $mlb-color-text;
    }

    & #ot-sdk-btn {
      color: $mlb-color-text !important;
      background-color: transparent !important;
    }

    & .cmp-title__text {
      color: $mlb-color-text;
    }

    & .cmp-separator__horizontal-rule {
      margin-bottom: 0;
    }
  }

  .slider_wrapper {
    display: none;
  }
}

.quiz_ans_bg {
  background-image: url("./resources/images/june_quiz/quiz_ans_bg.png");
  // background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  & .quiz-ans-img {
    margin-top: 50px;
    width: 100%;
    height: 0;
    padding-bottom: 100%;
    background-image: url("./resources/images/june_quiz/quiz_ans_img.png");
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
  }

  & #main-footer {
    & .cmp-text p a {
      color: $mlb-color-text-inverted;
    }

    & #ot-sdk-btn {
      color: $mlb-color-text-inverted !important;
      background-color: transparent !important;
    }

    & .cmp-title__text {
      color: $mlb-color-text-inverted;
    }
  }
}

#cmp_participation_head {
  margin: 0 25px 30px;
  text-align: center;

  & h1 {
    font-size: 30px;
  }
}

.full_width_page_text {
  margin: 0 20px;
  text-align: left;

  & a {
    word-break: break-word;
  }
}

#quiz-bg-img img {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: -1;
  margin-top: 47px;
}

// .quiz_ans_bg {
//     background-image: url('./resources/images/quiz_3_q_bg.png')
// }

// .quiz_que_bg {
//     background-image: url('./resources/images/quiz_3_q_bg.png')
// }
// @media screen and (min-width: ($xxs + 1)) {
//     .quiz-page {
//         padding-top: 20px;

//         & .cmp_quiz-wrapper {
//             & .form_heading {
//                 & h1 {
//                     font-size: 39px;
//                     line-height: 40px;
//                 }

//                 & h2 {
//                     font-size: 28px;
//                     line-height: 30px;
//                 }
//             }
//         }
//     }
// }

@media screen and (min-width: ($sm + 1)) {
  #cmp_participation_head {
    & h1 {
      font-size: 54px;
    }
  }
}
