.f6-branded {
  padding: 0;

  & #home-page-heading1 {
    & h1 {
      font-family: "patua one", sans-serif;
      font-size: 22px;
      line-height: 24px;
      text-align: center;
      color: $f6-color-accent;
      padding: 20px 0 10px 15px;
      margin: 0;
    }
  }

  & #home-page-heading2 {
    & h3 {
      font-family: "patua one", sans-serif;
      font-size: 25px;
      line-height: 29px;
      letter-spacing: -0.25px;
      text-align: left;
      color: $f6-color-text;
      margin: 0 auto 15px;
      padding: 0 20px;
    }
  }
  & #home-page-heading-img {
    & img {
      width: 80%;
      height: auto;
      margin: 0 auto;
    }
  }

  & #home-page-description1 {
    & p {
      font-family: "helvetica neue condensed", sans-serif;
      font-size: 20px;
      line-height: 22px;
      text-align: left;
      color: $f6-color-text;
      margin: 0 auto;
      padding: 10px 20px;
      & b {
        font-weight: bold;
      }
    }
  }

  & #home-page-description2 {
    & p {
      font-family: "helvetica neue condensed", sans-serif;
      font-size: 14px;
      line-height: 21px;
      letter-spacing: -0.12px;
      margin: 0 auto;
      color: $f6-color-text;
    }
  }

  & .btn-quiz {
    // margin-top: 40px;
    background: $f6-color-accent;
    width: fit-content;
    padding: 10px 50px;
    margin: 27px auto;

    & #quiz-cta {
      background: transparent;
      border: none;
      margin: 0;

      & span {
        font-family: "patua one", sans-serif;
        font-size: 22px;
        line-height: 34px;
      }

      &:hover {
        color: $f6-color-text-inverted;
      }
    }
  }
  & #home-quiz-tc-link {
    margin: 0 auto 21px;
    text-align: center;

    & p {
      & a {
        font-family: "helvetica neue condensed", sans-serif;
        font-size: 22px;
        line-height: 33px;
        color: $f6-color-text;
        text-decoration: underline;
      }
    }
  }
  & #quizPopup {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(2px);
    z-index: 9;
    & > div {
      width: 80%;
      height: auto;
      text-align: center;
      z-index: 9;
      background: linear-gradient(
        41deg,
        rgb(74, 152, 212) 0%,
        rgb(6, 91, 156) 53%,
        rgba(0, 80, 141, 1) 100%
      );
      border: none;
      border-radius: 5px;
      // padding-bottom: 27px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      padding: 30px 20px;
      & h2 {
        font-family: "patua one", sans-serif;
        font-size: 31px;
        line-height: 34px;
        color: $color-text-inverted;
        margin-top: 0;
      }
      & p {
        font-family: "helvetica", sans-serif;
        font-size: 16px;
        line-height: 25px;
        color: $color-text-inverted;
      }
      & .button {
        width: fit-content;
        margin: 50px auto 0;
        background-color: $color-background;
        padding: 0 20px;
        & #popupButton {
          color: $color-text;
          background-color: transparent;
          & span {
            color: $f6-color-accent;
            font-family: "patua one", sans-serif;
            font-size: 20px;
            line-height: 32px;
          }
        }
      }
    }
  }

  & .overlay-container {
    & #home-page-overlay {
      background-color: $f6-color-background;
      border: none;
      border-radius: 5px;

      & #homepage-overlay-heading p {
        font-family: "patua one", sans-serif;
        font-size: 21px;
        line-height: 25px;
        padding-bottom: 40px;
      }

      & #dealercode-and-timestamp p,
      b {
        font-family: "helvetica", sans-serif;
        font-size: 16px;
        line-height: 22px;
      }

      & button {
        background-color: $f6-color-accent;
        width: 90%;

        & span {
          font-family: "patua one", sans-serif;
          font-size: 22px;
          line-height: 34px;
        }
      }
    }
  }
  & .close-justscan {
    color: $color-text;
  }
}

#parliHomePage {
  & #parliHomeTeaserContainer {
    margin-top: 24px;
    & .cmp-teaser {
      display: flex;
      flex-direction: column-reverse;
      padding: 1rem;
      & .cmp-teaser__content {
        margin-top: 1rem;
        & .cmp-teaser__title {
          font-family: "helvetica neue medium", sans-serif;
          font-weight: 300;
          font-size: 16px;
          line-height: 24px;
          margin: 0;
          text-align: left;
          color: $parli-color-text;
        }
        & .cmp-teaser__description {
          margin-top: 0.5rem;
          p {
            font-family: "helvetica neue light", sans-serif;
            font-weight: 300;
            font-size: 14px;
            line-height: 18px;
            text-align: left;
            color: $parli-color-text;
          }
        }
      }
    }
  }
  & #parliRedBanner,
  & #parliBlueBanner {
    margin-top: 40px;
  }
}
@media screen and (min-width: $m) {
  #parliHomePage {
    padding: 0 0.5rem;
    & #parliHomeHeaderBanner {
      padding: 0 13px;
    }
    & #parliRedBanner,
    & #parliBlueBanner {
      padding: 0 13px;
    }
    & #parliHomeTeaserContainer {
      margin-top: 40px !important;
      > div {
        display: flex !important;
      }
      & .cmp-teaser {
        padding: 0 13px;
        max-width: 442px !important;
      }
    }
  }
}
