.f6-quiz {
  // background-image: url('./resources/images/quiz_3_q_bg.png');
  // background-repeat: no-repeat;
  // background-size: cover;
  padding: 0;
  background: transparent;

  & input[type="button"] {
    border: none;
    color: $f6-color-accent;
    background: $f6-color-background;
    margin: 0 auto;
    width: fit-content;
    padding: 0 40px;
    font-family: "patua one", sans-serif;
    font-size: 22px;
    text-align: center;
  }

  .cmp_quiz-wrapper {
    // background-image: url('./resources/images/icons/f6_branded/bg-img-quiz-question.jpg');
    // background-size: cover;
    // background-position: center;
    // background-repeat: no-repeat;
    // padding: 30px;

    & .form_heading {
      word-break: keep-all !important;
      padding-left: 30px;

      & h1 {
        font-family: "patua one", sans-serif;
        font-size: 42px;
        line-height: 45px;
        text-align: left;
        color: $f6-color-accent;
        margin: 31px auto 30px;
      }

      & h2 {
        font-family: "helvetica", sans-serif;
        font-size: 21px;
        line-height: 24px;
        text-align: left;
        color: $f6-color-text;
        margin: 31px auto 28px;
      }
    }

    & .answers {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 30px;

      & span {
        display: flex;
        justify-content: center;
        align-items: center;
        border: none;
        width: 100vw;
        position: relative;
        padding: 21px 0;
        height: 185px;
        text-align: center;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;

        // background: linear-gradient(0deg, rgba(0,0,0,0.2) 0%, rgba(255,255,255,0.2) 100%);
        &:nth-of-type(1) {
          background-image: url("./resources/images/icons/f6_branded/img-quiz-option-1-bg.jpg");
        }

        &:nth-of-type(2) {
          background-image: url("./resources/images/icons/f6_branded/img-quiz-option-2-bg.jpg");
        }

        &:nth-of-type(3) {
          background-image: url("./resources/images/icons/f6_branded/img-quiz-option-3-bg.png");
        }

        & input[type="radio"] {
          background-color: unset;
          border: none;
          width: 100%;
          height: 100%;
          position: absolute;
          margin: 0;
          border-radius: 0;
        }

        & label {
          color: $f6-color-accent;
          background-color: $f6-color-background;
          padding: 8px 24px;
          border-radius: 200px;
          font-family: "patua one", sans-serif;
          font-size: 21px;
          margin-top: auto;
          text-align: center;
        }
      }
    }

    & .btn-quiz-tc {
      text-align: center;

      & p {
        & a {
          font-family: "helvetica neue condensed", sans-serif;
          font-size: 24px;
          line-height: 25px;
          color: $f6-color-text;
          text-decoration: underline;
        }
      }
    }
  }

  & .cmp_wrong_ans-wrapper {
    background-image: url("./resources/images/icons/f6_branded/bg-img-quiz-answers.jpg");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    padding: 30px;

    & .cmp_wrong_ans {
      flex-direction: column;

      & .cmp_head_wrongans h2 {
        width: 100%;
        color: $f6-color-text-inverted;
        text-align: center;
        margin-bottom: 25px;
        font-family: "patua one", sans-serif;
        font-size: 32px;
        line-height: 39px;
      }

      & .cmp_desc_wrongans p {
        width: 100%;
        color: $f6-color-text-inverted;
        text-align: center;
        margin-bottom: 40px;
        font-family: "helvetica neue condensed", sans-serif;
        font-size: 24px;
        line-height: 27px;
      }

      & .btn-quiz-tc {
        text-align: center;

        & p {
          & a {
            font-family: "helvetica neue condensed", sans-serif;
            font-size: 24px;
            line-height: 25px;
            color: $f6-color-text-inverted;
            text-decoration: underline;
          }
        }
      }

      & .quiz-ans-img {
        margin-top: 40px;
        width: 100%;
        height: 0;
        padding-bottom: 100%;
        background-image: url("./resources/images/icons/f6_branded/img-wrong-answer.jpg");
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
      }
    }
  }

  & .cmp_correct_ans-wrapper {
    background-image: url("./resources/images/icons/f6_branded/bg-img-quiz-answers.jpg");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    padding: 30px;

    & .cmp_correct_ans {
      flex-direction: column;

      & .cmp_head_correctans h2 {
        font-family: "patua one", sans-serif;
        font-size: 32px;
        line-height: 39px;
        width: 100%;
        color: $f6-color-text-inverted;
        text-align: center;
        margin-bottom: 20px;
      }

      & .cmp_desc_correctans p {
        font-family: "helvetica neue condensed", sans-serif;
        font-size: 19px;
        line-height: 21px;
        width: 100%;
        color: $f6-color-text-inverted;
        text-align: center;
        margin-bottom: 75px;
      }

      & .btn-quiz-tc {
        text-align: center;

        & p {
          & a {
            font-family: "helvetica neue condensed", sans-serif;
            font-size: 24px;
            line-height: 25px;
            color: $f6-color-text-inverted;
            text-decoration: underline;
          }
        }
      }

      & .quiz-ans-img {
        margin-top: 0;
        width: 100%;
        height: 0;
        padding-bottom: 100%;
        background-image: url("./resources/images/icons/f6_branded/img-correct-answer.jpg");
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
      }
    }
  }

  & #main-footer {
    & .cmp-text p a {
      color: $color-text;
    }

    & #ot-sdk-btn {
      color: $color-text !important;
      background-color: transparent !important;
    }

    & .cmp-title__text {
      color: $color-text;
    }

    & .cmp-separator__horizontal-rule {
      margin-bottom: 0;
    }
  }

  .slider_wrapper {
    display: none;
  }
}
