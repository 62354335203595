.home-page {
  padding: 0;
  padding-top: 47px;

  & .cmp-text {
    margin: 0;
  }

  & #homepage-description1 p {
    font-size: 21px;
    line-height: 25px;
    text-align: left;
    padding: 0 29px 20px 21px;
    margin: 0;
    color: $mlb-color-text;
  }

  & #homepage-description2 p {
    font-size: 18px;
    line-height: 25px;
    text-align: left;
    padding: 33px 13px 46px 21px;
    margin: 0;
    color: $mlb-color-text;
    font-family: "neuland grotesk condensed light", sans-serif;
  }

  & #homepage-heading1 {
    padding: 32px 0 19px 21px;

    & h1 {
      font-family: "neuland grotesk condensed bold", sans-serif;
      font-size: 48px;
      text-align: left;
      line-height: 52px;
      font-weight: bold;
      color: $mlb-color-text;
      margin: 0 !important;

      & .cmp-red-title {
        color: $mlb-color-accent;
      }
    }
  }

  & #homepage-heading2 {
    padding: 0 0 18px 21px;
    margin: 0;

    & h2 {
      font-size: 23px;
      line-height: 25px;
      text-align: left;
      margin: 0;
      color: $mlb-color-text;
      font-family: "neuland grotesk condensed bold", sans-serif;
    }
  }

  .btn-pick-the-pic {
    width: fit-content;
    margin: 0 auto;
    padding: 0 30px;

    & a {
      &:hover {
        color: $mlb-color-text-inverted;
        text-decoration: underline;
      }
    }
  }

  #main-footer {
    padding-left: 36px;
    padding-right: 36px;
    // padding-top: 0;
    // & .cmp-separator__horizontal-rule{
    //   margin-top: 0;
    // }
  }
}

.cmp-video {
  width: 100%;
  margin: 0 auto;
  // padding: 10px !important;

  & iframe {
    width: 100%;
    // border-radius: 10px;

    & html,
    body,
    .player-design,
    #player,
    .video-display {
      background-color: transparent !important;
    }
  }
}
