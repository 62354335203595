@import "../../mixins/_mixins.scss";

.unbranded-home-page {
  & .freestyle-form-container {
    min-height: 60vh;
  }

  & .form_heading {
    margin: 0;
    padding: 0;
  }

  & .form_heading:first-of-type {
    margin: 0;
    padding: 46px 0 24px;

    & h1 {
      font-size: 38px;
      line-height: 45px;
      text-align: center;
      margin: 0;
      padding: 0;
      position: relative;
    }

    & h2 {
      margin: 0;
    }
  }

  & .cmp_reg_form5-wrapper {
    & .form_heading:first-of-type {
      padding: 10px 0 39px;
    }
  }

  & .cmp_reg_form6-wrapper {
    & .form_heading {
      &:first-of-type {
        padding: 0;
      }

      & h2 {
        font-family: "neuland grotesk condensed bold", sans-serif;
        font-size: 24px;
        line-height: 32px;
        text-align: center;
        color: $mlb-color-text;
        margin-top: 39px;
      }
    }

    & .cmp_reg_form6 {
      & .dataMatchExhaustedScreenTitle {
        text-align: center;
        margin-top: 40px;
      }

      & .idMatchAbort {
        background-color: transparent !important;
        color: $mlb-color-text;
      }

      & .idMatchAbortDesc {
        background-color: transparent;
        text-align: center;
      }
    }
  }

  & .cmp_reg_form7-wrapper {
    & .form_heading {
      &:first-of-type {
        padding: 0;
      }
    }

    & .verificationDesc {
      & h2 {
        font-family: "neuland grotesk condensed bold", sans-serif;
        font-size: 21px;
        line-height: 32px;
        text-align: center;
        color: $mlb-color-text;
        margin-top: 39px;
        margin-bottom: 40px;
        padding: 0 10px;
      }

      & p {
        text-align: center;
        font-size: 16px;
        line-height: 22px;
        font-family: "helvetica", sans-serif;
        padding: 0 10px;
      }
    }
  }

  & .cmp_reg_form8-wrapper {
    & .form_heading {
      &:first-of-type {
        padding: 0;
      }

      & h2 {
        font-family: "neuland grotesk condensed bold", sans-serif;
        font-size: 24px;
        line-height: 32px;
        text-align: center;
        color: $mlb-color-text;
        margin-top: 39px;
      }
    }

    & .cmp_reg_form8 {
      margin-top: 40px;

      & .notVerifiedTryAgainDescription {
        text-align: center;
      }

      & .notVerifiedDescription {
        text-align: center;
      }
    }
  }

  & .form_main {
    & .password-policy {
      margin-top: 21px;

      & p {
        font-size: 16px;
        line-height: 22px;
        font-family: "helvetica", sans-serif;
        color: $mlb-color-text;
      }

      & ul li {
        font-size: 12px;
        line-height: 20px;
      }
    }

    & .marketingOptIn {
      margin-top: 49px;

      & span p,
      h5 {
        font-size: 16px;
        line-height: 22px;
      }

      & a {
        color: $mlb-color-accent;
        text-decoration-thickness: 1px;
      }
    }

    & .privacyPolicy {
      & span p {
        font-size: 16px;
        line-height: 22px;
      }

      & a {
        color: $mlb-color-accent;
        text-decoration-thickness: 1px;
      }
    }

    & .necessary {
      margin: 30px 0 32px;
      text-align: left;

      & p {
        margin: 0;
        font-size: 16px;
        line-height: 32px;
      }
    }

    & .gender {
      & .form_radio_group {
        & input[type="radio"] {
          accent-color: $mlb-color-accent;

          &:checked {
            appearance: radio;
          }
        }

        & label {
          font-family: "helvetica", sans-serif;
          font-size: 16px;
          line-height: 32px;
          color: $mlb-color-text;
        }
      }
    }

    & .dateOfBirth {
      & label {
        font-family: "helvetica", sans-serif;
        font-size: 16px;
        line-height: 32px;
        color: $mlb-color-text;
      }
    }

    & .streetName {
      width: 60%;
    }

    & .streetNumber {
      width: 38%;
    }

    & .telephoneNumberPrefix {
      display: flex;
      align-items: center;
      justify-content: center;
      color: $mlb-color-input;
    }

    & .notice {
      & h2 {
        font-family: "neuland grotesk condensed bold", sans-serif;
        font-size: 32px;
        line-height: 45px;
        margin-bottom: 39px;
        color: $mlb-color-text;
      }
      & p {
        font-family: "helvetica", sans-serif;
        font-size: 16px;
        line-height: 20px;
        color: $mlb-color-text;
      }
      & a {
        color: $mlb-color-text;
        font-size: 16px;
        line-height: 20px;
      }
    }

    & .choose-brand,
    .dealer-code {
      padding-bottom: 20px;
      margin: 0;

      & p {
        color: $mlb-color-text;
        font-size: 16px;
        line-height: 20px;
        margin: 0;
      }
    }

    & .choose-brand {
      padding-top: 15px;
    }

    & .dealer-code {
      padding-top: 39px;
    }

    & .dataMatchTitle {
      margin-bottom: 41px;

      & p {
        text-align: center;
        padding: 0 5px;
      }
    }

    & .dataMatchPrevious {
      margin: 0 !important;
      background-image: url("./resources/images/icons/icon-try-again.png");
      background-repeat: no-repeat;
      background-position: 20px center;
      background-size: 25px auto;
    }

    & .idMatchUpload-container {
      background: none;
      padding: 0 25px;

      & .idMatchUpload {
        margin: 46px 0 27px !important;
        background-image: url("./resources/images/icons/icon-upload-passport.png");
        background-repeat: no-repeat;
        background-position: 20px center;
        background-size: 25px auto;
      }
    }

    & .idMatchDesc {
      background: none;
      padding-top: 0;

      & p {
        text-align: center;
      }
    }

    & .error-message {
      color: $color-red-shade1;
      font-size: 15px;
      padding: 0 5px;
      text-align: center;
      font-family: "helvetica", sans-serif;
    }
  }

  // forgot password screen
  & .cmp_forgot_password-wrapper {
    & .form_heading {
      &:first-of-type {
        padding: 0 0 24px;
      }

      & h1::before {
        @include headingIcon(
          "./resources/images/icons/icon-forgot-password.png"
        );
      }

      & h2 {
        font-size: 16px;
        line-height: 22px;
        font-family: "helvetica", sans-serif;
        margin: 0;
        padding: 0 0 24px;
      }
    }

    & .form_main {
      & .necessary {
        margin: 5px auto 32px;
      }
    }
  }

  // reset password screen
  & .cmp_reset_password-wrapper {
    & .form_heading {
      &:first-of-type {
        padding: 0 0 24px;
      }

      & h1::before {
        @include headingIcon(
          "./resources/images/icons/icon-create-password.png"
        );
      }

      & h2 {
        font-size: 16px;
        line-height: 22px;
        font-family: "helvetica", sans-serif;
        margin: 0;
        padding: 0 0 24px;
      }
    }

    & .form_main {
      & .necessary {
        margin: 25px auto 22px;
      }
    }
  }
}
